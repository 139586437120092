import React, { useEffect, useRef, useState } from "react";
import styles from "./text.module.css";

export default function Text({
  value,
  onChange,
  className,
  style,
  disabled,
  placeholder,
}) {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  return (
    <div className={className}>
      <input
        style={style}
        className={styles.container}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}
