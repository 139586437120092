import React, { useEffect, useState } from "react";
import ShopHeader from "../components/header/header";
import axiosInstance from "../../AxiosInstance";
import styles from "./categories.module.css";
import { useNavigate } from "react-router-dom";
import DeliveryText from "../components/delivery_text/delivery_text";
import PageTitle from "../components/page_title/page_title";
import Cart from "../components/cart/cart";
import ProductCard from "../components/product_card/product_card";
import productstyles from "../products/products.module.css";
import ProductModal from "../components/product_modal/product_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Text from "../../components/ajonjolib/inputs/text/text";
import Loading from "../../admin/components/loading";
import { useQuery } from "@tanstack/react-query";
import useDistrict from "../../hooks/useDistrict";
import { useDebounce } from "use-debounce";
import { queryClient } from "../../App";

function CategorySquare({ category, categories, promoData }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.category}
      onClick={() =>
        navigate("/products/", {
          state: { category: category, categories: categories, promoData },
        })
      }
      // onMouseDown={handleMouseDown}
    >
      <div className={"pb-2"}>{category.name}</div>
      <img
        alt={"category_image"}
        src={category.image.image}
        style={{ objectFit: "contain", width: "100%", height: "auto" }}
      />
    </div>
  );
}

export default function Categories() {
  // const [showPromos, setShowPromos] = useState(false);
  // const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({});
  // const [showSearch, setShowSearch] = useState(false);
  // const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [refreshCart, setRefreshCart] = useState(0);
  const [quantity, setQuantity] = useState(1);
  // const district = JSON.parse(localStorage.getItem("district"));
  const district = useDistrict();
  const [showLogin, setShowLogin] = useState(0);
  const [locked, setLocked] = useState(false);
  const [debouncedText] = useDebounce(form["query"], 800);
  // const [loading, setLoading] = useState(false); // Ajout de l'état pour le loader

  // const getPromoProducts = async () => {
  //   // setLoading(true);
  //   axiosInstance
  //     .get(`product/promos/?unlogged_warehouse=${district.warehouse_id}`)
  //     .then((response) => {
  //       setShowPromos(response?.data?.results.length > 0);
  //     });
  //   // .finally(() => setLoading(false));
  // };

  // Queries
  const categoryQuery = useQuery({
    queryKey: [{ type: "sections", id: 6 }],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`section/6/`);

      if (data) {
        return [
          {
            id: -1,
            name: "Promotions",
            image: { image: "/promo.png" },
          },
          ...data,
        ];
      }
      return data;
    },
  });

  // Queries
  const productQuery = useQuery({
    queryKey: [
      {
        type: "product-category-" + district.warehouse_id,
        query: debouncedText,
      },
    ],
    enabled: !!debouncedText,
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `product/?max_query=10&unlogged_warehouse=${
          district.warehouse_id
        }&all_warehouse=${true}&combos=${true}&shop=1&quantity=1&name=${debouncedText}`
        // `c/products/?max_query=10&all_warehouse=${true}&combos=${true}&shop=1&quantity=1&name=${debouncedText}`
      );
      return data?.results;
    },
  });

  // Queries
  const promoQuery = useQuery({
    queryKey: [
      {
        type: "promos",
        query: district.warehouse_id,
      },
    ],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `product/promos/?unlogged_warehouse=${district.warehouse_id}`
      );
      return data?.results;
    },
  });

  // console.log({ promoQuery });

  useEffect(() => {
    document.title = "Categories | Bring Me";
    // setLoading(true);
    // axiosInstance
    //   .get("section/6/")
    //   .then((response) => {
    //     setCategories([
    //       {
    //         id: -1,
    //         name: "Promotions",
    //         image: { image: "/promo.png" },
    //       },
    //       ...response?.data,
    //     ]);
    //   })
    //   .finally(() => setLoading(false)); // Cacher le loader après la requête
    // getPromoProducts();
  }, []);

  // useEffect(() => {
  // if (form["query"] === undefined) {
  //   setShowSearch(false);
  //   return;
  // }
  // const query = form["query"];
  // if (query !== "") {
  //   axiosInstance
  //     .get(
  //       `product/?max_query=10&all_warehouse=${true}&combos=${true}&shop=1&quantity=1&name=${query}`
  //     )
  //     .then((response) => {
  //       setProducts(response?.data.results);
  //       setShowSearch(true);
  //     });
  // } else {
  //   setShowSearch(false);
  // }
  // }, [form["query"]]);

  const addToCart = () => {
    axiosInstance
      .post(`cart/add/${selectedProduct.id}/`, {
        quantity: quantity,
        district_id: district.id,
      })
      .then((response) => {
        if (response?.response?.status === 500) {
          setShowLogin((prev) => prev + 1);
        }
        setShowModal(false);
        setQuantity(1);
        setRefreshCart((prev) => prev + 1);
      });
  };

  const checkQuantity = (newQuantity) => {
    setLocked(true);
    if (newQuantity < 0 && quantity === 1) return;

    axiosInstance
      .get(`product/${selectedProduct.id}/count/${district.id}/`)
      .then((response) => {
        const foundQuantity = response?.data?.quantity;
        if (foundQuantity >= quantity + newQuantity) {
          setQuantity((prevQuantity) => prevQuantity + newQuantity);
        }
        setLocked(false);
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, query: e });
  };

  // console.log({ promoQuery });

  const handleMouseDown = async (data) => {
    const queryKey = [
      "product-category-details",
      {
        id: `${district.warehouse_id}-${district.id}-${data?.id}`,
      },
    ];

    // Vérification des données dans le cache
    const cachedData = queryClient.getQueryData(queryKey);

    try {
      if (!cachedData) {
        console.log("Préfetching en cours...");
        await queryClient.prefetchQuery({
          queryKey, // Utilisation de la même clé pour cohérence
          queryFn: async () => {
            const response = await axiosInstance.get(
              `c/subcategory/?category_id=${data?.id}`
            );
            return response.data;
          },
        });
        console.log("Préfetching terminé avec succès !");
      } else {
        console.log("Les données sont déjà en cache, pas de préfetching.");
      }
    } catch (error) {
      console.error("Erreur lors du préfetching :", error);
    }
  };

  return (
    <div>
      <ShopHeader showLogin={showLogin} />

      <Cart refreshCart={refreshCart} outCartItems={setCartItems} />

      <div>
        <ProductModal
          locked={locked}
          quantity={quantity}
          setShowModal={setShowModal}
          showModal={showModal}
          selectedProduct={selectedProduct}
          addToCart={addToCart}
          checkQuantity={checkQuantity}
        />
      </div>

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle
          name={"Epicerie en ligne"}
          description={"Livré en 20 minutes"}
        >
          <div
            className={"d-flex justify-content-center mt-2 align-items-center"}
          >
            <div className={`${styles.responsiveSearch} me-2`}>
              <Text
                style={{ width: "100%" }}
                value={form["query"]}
                onChange={handleChange}
                placeholder={"Recherchez vos produits à partir d'ici"}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faX}
                size={"lg"}
                color={"#00A6A8"}
                onClick={() => setForm({ ...form, query: "" })}
              />
            </div>
          </div>
        </PageTitle>

        <div className={`${styles.responsiveContainer} pb-5`}>
          <DeliveryText />
          {productQuery?.isLoading ? (
            <Loading />
          ) : form?.query ? (
            // <div className={`${productstyles.responsiveGridWarehouse}`}>
            <div className={`${productstyles.responsiveFlexWarehouse}`}>
              {productQuery?.data &&
                productQuery?.data.map((product, index2) => (
                  <div
                    key={product.id}
                    className={`d-flex justify-content-center mb-2 ${productstyles.responsiveProductWarehouse}`}
                    // className={styles.responsiveGridWarehouseContainer}
                  >
                    <ProductCard
                      setShowLogin={setShowLogin}
                      cartItems={cartItems}
                      product={product}
                      setRefreshCart={setRefreshCart}
                      onClick={() => {
                        setSelectedProduct(product);
                        setShowModal(true);
                      }}
                    />
                  </div>
                ))}
            </div>
          ) : (
            // <div className={`${styles.gridContainer}`}>
            //   {categoryQuery?.isLoading ? (
            //     <Loading />
            //   ) : (
            //     categoryQuery?.data.map((category, index) => {
            //       if (category.id === -1 && !!!promoQuery?.data) return null;
            //       return (
            //         <div className={`${styles.categoryContainer}`} key={index}>
            //           <CategorySquare
            //             category={category}
            //             categories={categoryQuery?.data}
            //           />
            //         </div>
            //       );
            //     })
            //   )}
            // </div>
            <div className={`d-flex flex-wrap justify-content-between pt-4`}>
              {categoryQuery?.isLoading ? (
                <Loading />
              ) : (
                categoryQuery?.data.map((category, index) => {
                  if (category.id === -1 && promoQuery?.data?.length === 0)
                    return null;
                  return (
                    <div
                      className={`${styles.categoryContainer}`}
                      key={index}
                      onMouseDown={() => handleMouseDown(category)}
                    >
                      <CategorySquare
                        category={category}
                        categories={categoryQuery?.data}
                        promoData={productQuery?.data || []}
                        // handleMouseDown={() => handleMouseDown(category)}
                      />
                    </div>
                  );
                })
              )}
            </div>
            // <div className={`d-flex flex-wrap justify-content-between pt-4`}>
            //   {categoryQuery?.isLoading ? (
            //     <Loading />
            //   ) : (
            //     categoryQuery?.data.map((category, index) => {
            //       if (category.id === -1 && !!!promoQuery?.data) return null;
            //       return (
            //         <div className={`${styles.categoryContainer}`} key={index}>
            //           <CategorySquare
            //             category={category}
            //             categories={categoryQuery?.data}
            //           />
            //         </div>
            //       );
            //     })
            //   )}
            // </div>
          )}

          {/* {showSearch ? (
            <div className={`${productstyles.responsiveFlexWarehouse}`}>
              {products.map((product, index2) => (
                <div
                  key={product.id}
                  className={`d-flex justify-content-center mb-2 ${productstyles.responsiveProductWarehouse}`}
                >
                  <ProductCard
                    setShowLogin={setShowLogin}
                    cartItems={cartItems}
                    product={product}
                    setRefreshCart={setRefreshCart}
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowModal(true);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={`d-flex flex-wrap justify-content-between pt-4`}>
              {categories.map((category, index) => {
                if (category.id === -1 && !showPromos) return null;
                return (
                  <div className={`${styles.categoryContainer}`}>
                    <CategorySquare
                      category={category}
                      categories={categories}
                      key={index}
                    />
                  </div>
                );
              })}
            </div>
          )
          } */}
        </div>
      </div>
    </div>
  );
}
