import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useError } from "../providers/ErrorProvider";

export const ErrorPopup = () => {
  const { error, clearError } = useError();

  React.useEffect(() => {
    if (error) {
      toast.error(error, {
        onClose: clearError,
      });
    }
  }, [error, clearError]);

  return <ToastContainer />;
};

// import React from "react";
// import { useError } from "../providers/ErrorProvider";
// // import { useError } from "./ErrorContext";

// export const ErrorPopup = () => {
//   const { error, clearError } = useError();

//   if (!error) return null;

//   return (
//     <div
//       style={{
//         position: "fixed",
//         top: 20,
//         right: 20,
//         backgroundColor: "red",
//         padding: "1rem",
//         color: "white",
//         borderRadius: "5px",
//         zIndex: 9999,
//       }}
//     >
//       <p>{error}</p>
//       <button
//         onClick={() => window.location.reload()}
//         style={{ marginRight: "1rem" }}
//       >
//         Recharger
//       </button>
//       <button onClick={clearError}>Fermer</button>
//     </div>
//   );
// };
