import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_token: null,
  refresh_token: null,
  district: null,
  permissions: null,
  isAuthenticated: false,
  warehouse_id: null,
  biller_id: null,
  authorizedAddress: null,
  version: 0,
};

const useSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.permissions = payload.permissions;
      state.isAuthenticated = true;
      state.warehouse_id = payload?.warehouse_id || null;
      state.biller_id = payload?.biller_id || null;
      state.version = 1;
    },
    logout: (state) => {
      state.access_token = null;
      state.permissions = null;
      state.district = null;
      state.isAuthenticated = false;
      state.warehouse_id = null;
      state.biller_id = null;
    },
    setDistrict: (state, { payload }) => {
      state.district = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.access_token = payload;
    },
    setAuthorizedAddress: (state, { payload }) => {
      state.authorizedAddress = payload;
    },
  },
});

export const getStoreVersion = (state) => state?.persist?.user?.version;
export const getUserStatus = (state) => state?.persist?.user?.isAuthenticated;
export const getUserToken = (state) => state?.persist?.user?.access_token;
export const getUserDistrict = (state) => state?.persist?.user?.district;
export const getUserPermissions = (state) => state?.persist?.user?.permissions;
export const getUserBillerId = (state) => state?.persist?.user?.biller_id;
export const getUserWarehouseId = (state) => state?.persist?.user?.warehouse_id;
export const getUserAuthorizedAddress = (state) =>
  state?.persist?.user?.authorizedAddress;

export const {
  setCredentials,
  logout,
  setDistrict,
  setAccessToken,
  setAuthorizedAddress,
} = useSlice.actions;
export default useSlice.reducer;
