// import React, { useEffect } from "react";
// import TableComponent from "./table";
// import TableHeader from "./table_header";
// import Loading from "./loading";
// import PaginationAdmin from "./pagination/pagination";
// import { useTableData } from "../hooks/useTableData";

// export default function TableView({
//   children,
//   fetchUrl,
//   title,
//   pull,
//   noCreate,
//   noPagination,
//   cols,
//   rows,
//   withIndex,
//   withImport,
//   defaultFilters,
//   limit = 30,
//   useReactQuery = false,
//   refetchInterval = false,
// }) {
//   // const [isLoading, setIsLoading] = useState(true);

//   // useEffect(() => {
//   //   if (rows?.length !== 0) {
//   //     setIsLoading(false);
//   //   }
//   // }, [rows]);

//   // const [isLoading, setIsLoading] = useState(true);
//   // const {
//   //   data,
//   //   error,
//   //   isLoading: queryLoading,
//   // } = useTableData(fetchUrl, defaultFilters, limit);

//   // useEffect(() => {
//   //   if (!useReactQuery && rows?.length !== 0) {
//   //     setIsLoading(false);
//   //   } else if (useReactQuery && data) {
//   //     pull(data.results || data);
//   //     setIsLoading(queryLoading);
//   //   }
//   // }, [rows, data, useReactQuery, queryLoading]);

//   const { data, isLoading, setCurrentPage } = useTableData(
//     fetchUrl,
//     defaultFilters,
//     limit,
//     useReactQuery,
//     refetchInterval
//   );

//   useEffect(() => {
//     if (useReactQuery && data) {
//       pull(data.results || data);
//     }
//   }, [data, useReactQuery, pull]);

//   const handlePageChange = (page) => {
//     if (useReactQuery) {
//       setCurrentPage(page); // Change la page dans React Query
//     }
//   };

//   return (
//     <div>
//       <TableHeader
//         title={title}
//         noCreate={noCreate}
//         withImport={withImport}
//         fetchUrl={fetchUrl}
//         queryName={useReactQuery}
//       />

//       {children}

//       <div className={"my-3"}>
//         <div style={{ height: "65px" }}>
//           <PaginationAdmin
//             prop={fetchUrl}
//             func={pull}
//             limit={limit}
//             defaultFilters={defaultFilters}
//             onPageChange={handlePageChange}
//           />
//         </div>
//         {isLoading ? (
//           <Loading />
//         ) : (
//           <div
//             style={{
//               overflowX: "scroll",
//             }}
//           >
//             <TableComponent
//               fetchUrl={fetchUrl}
//               defaultFilters={defaultFilters}
//               noPagination={noPagination}
//               pull={pull}
//               cols={cols}
//               rows={rows}
//               withIndex={withIndex}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import TableComponent from "./table";
import TableHeader from "./table_header";
import Loading from "./loading";
import PaginationAdmin from "./pagination/pagination";
import { useTableData } from "../hooks/useTableData";

export default function TableView({
  children,
  fetchUrl,
  title,
  pull,
  noCreate,
  noPagination,
  cols,
  rows,
  withIndex,
  withImport,
  defaultFilters,
  limit = 30,
  useReactQuery = false,
  refetchInterval = false,
}) {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (rows?.length !== 0) {
  //     setIsLoading(false);
  //   }
  // }, [rows]);

  // const [isLoading, setIsLoading] = useState(true);
  // const {
  //   data,
  //   error,
  //   isLoading: queryLoading,
  // } = useTableData(fetchUrl, defaultFilters, limit);

  // useEffect(() => {
  //   if (!useReactQuery && rows?.length !== 0) {
  //     setIsLoading(false);
  //   } else if (useReactQuery && data) {
  //     pull(data.results || data);
  //     setIsLoading(queryLoading);
  //   }
  // }, [rows, data, useReactQuery, queryLoading]);

  const { data, isLoading, setCurrentPage } = useTableData(
    fetchUrl,
    defaultFilters,
    limit,
    useReactQuery,
    refetchInterval
  );

  useEffect(() => {
    if (useReactQuery && data) {
      pull(data.results || data);
    }
  }, [data, useReactQuery, pull]);

  const handlePageChange = (page) => {
    if (useReactQuery) {
      setCurrentPage(page); // Change la page dans React Query
    }
  };

  return (
    <div>
      <TableHeader
        title={title}
        noCreate={noCreate}
        withImport={withImport}
        fetchUrl={fetchUrl}
        queryName={useReactQuery}
      />

      {children}

      <div className={"my-3"}>
        <div style={{ height: "65px" }}>
          <PaginationAdmin
            prop={fetchUrl}
            func={pull}
            limit={limit}
            defaultFilters={defaultFilters}
            onPageChange={handlePageChange}
          />
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              overflowX: "scroll",
            }}
          >
            <TableComponent
              fetchUrl={fetchUrl}
              defaultFilters={defaultFilters}
              noPagination={noPagination}
              pull={pull}
              cols={cols}
              rows={rows}
              withIndex={withIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
}
