// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: block;
  width: 24px;
  height: 24px;
  border: 3px solid #009f7f;
  border-radius: 50%;
  border-top-color: #ccc;
  animation: spin 1s linear infinite;
}


.error-container {
  padding: 2rem;
  margin: 2rem;
  border: 1px solid #ff0000;
  border-radius: 4px;
  background-color: #fff5f5;
}

.error-container h2 {
  color: #e53e3e;
  margin-bottom: 1rem;
}

.error-container pre {
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,kCAAkC;AACpC;;;AAGA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner {\n  display: block;\n  width: 24px;\n  height: 24px;\n  border: 3px solid #009f7f;\n  border-radius: 50%;\n  border-top-color: #ccc;\n  animation: spin 1s linear infinite;\n}\n\n\n.error-container {\n  padding: 2rem;\n  margin: 2rem;\n  border: 1px solid #ff0000;\n  border-radius: 4px;\n  background-color: #fff5f5;\n}\n\n.error-container h2 {\n  color: #e53e3e;\n  margin-bottom: 1rem;\n}\n\n.error-container pre {\n  background-color: #fff;\n  padding: 1rem;\n  border-radius: 4px;\n  overflow-x: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
