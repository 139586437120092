import React, { useEffect, useState } from "react";
import ShopHeader from "../components/header/header";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import PageTitle from "../components/page_title/page_title";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "../../admin/components/inputs";
import Modal from "../../admin/components/modal/modal";
import useAuthenticate from "../../hooks/useAuthenticate";

export default function Profile() {
  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const isAuth = useAuthenticate();

  useEffect(() => {
    document.title = "Profile | Bring Me";
    if (isAuth) {
      axiosInstance.get("current_user/").then((response) => {
        if (response?.response?.status === 500) {
          localStorage.removeItem("access_token");
        } else {
          setForm({
            name: response?.data.user_data.name,
            lastname: response?.data.user_data.lastname,
            email: response?.data.user_data.email,
            phone_number: response?.data.user_data.phone_number,
          });
        }
      });
    }
  }, []);

  const submit = () => {
    axiosInstance
      .put("current_user/1/", {
        name: form["name"],
        lastname: form["lastname"],
        email: form["email"],
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const logout = () => {
    localStorage.clear();
    axiosInstance.defaults.headers.common["Authorization"] = null;
    window.location.replace("/locate");
  };

  const deleteAccount = () => {
    axiosInstance.delete("current_user/1/").then((response) => {
      localStorage.clear();
      axiosInstance.defaults.headers.common["Authorization"] = null;
      window.location.replace("/locate");
    });
  };

  return (
    <div>
      <ShopHeader />

      <Modal
        show={showModal}
        setter={setShowModal}
        onClick={() => deleteAccount()}
        buttonName={"Supprimer mon compte"}
        name={"Voulez vous vraiment supprimer votre compte ?"}
      >
        <div>Toutes vos données seront perdues.</div>
      </Modal>

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle name="Votre profil" />

        <div className={"mx-5 pb-5 mt-5"}>
          <div className={"d-flex justify-content-between mb-3"}>
            <div style={{ flexBasis: "48%" }}>
              <TextInput
                name={"name"}
                placeholder={"Nom"}
                form={form}
                setForm={setForm}
              />
            </div>
            <div style={{ flexBasis: "48%" }}>
              <TextInput
                name={"lastname"}
                placeholder={"Prénom"}
                form={form}
                setForm={setForm}
              />
            </div>
          </div>
          <div className={"d-flex justify-content-between"}>
            <div style={{ flexBasis: "48%" }}>
              <TextInput
                name={"email"}
                placeholder={"email"}
                form={form}
                setForm={setForm}
              />
            </div>
          </div>
          <div className={"mt-4"}>
            <PrimaryButton name={"Enregister"} onClick={() => submit()} />
          </div>

          <div className={"mt-4"}>
            <SecondaryButton name={"Déconnexion"} onClick={() => logout()} />
          </div>

          <div className={"mt-4"}>
            <DangerButton
              name={"Supprimer mon compte"}
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
