import React, { useState } from "react";
import styles from "../../styles/login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../AxiosInstance";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../stores/features/userSlice";

export default function Login() {
  const [form, setForm] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setField = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    if (!form?.username || !form?.password) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setLoading(true);
    // return
    axiosInstance
      .post("login/", {
        username: form.username,
        password: form.password,
      })
      .then((response) => {
        // console.log({ response });
        // return;
        // setShowError(false);
        // localStorage.clear();
        // localStorage.setItem("access_token", response.data.access);
        // localStorage.setItem("refresh_token", response.data.refresh);
        // localStorage.setItem("permissions", response.data.role);
        // localStorage.setItem("warehouse_id", response.data.warehouse_id);
        // localStorage.setItem("biller_id", response.data.supplier_id);
        // axiosInstance.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${response.data.access}`;

        dispatch(
          setCredentials({
            warehouse_id: response?.data?.warehouse_id,
            biller_id: response?.data?.biller_id,
            permissions: response?.data?.role,
            access_token: response?.data?.access,
            refresh_token: response?.data?.refresh,
          })
        );
        // window.location.replace("/admin/dashboard");
        navigate("/admin/dashboard", { replace: true });
      })
      .catch((err) => {
        setShowError(true);
        return;
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container-fluid bg-light vh-100">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-md-6 col-lg-3 rounded p-5 bg-white shadow">
          <form onSubmit={submit}>
            <img src={"/logo.png"} width={"60%"} alt={"bringme logo"} />
            <div className={`${styles.imageText} mt-1`}>
              <i>Login to admin</i>
            </div>
            {showError && (
              <p style={{ color: "red" }}>Veuillez mettre les informations</p>
            )}
            <div className="form-group">
              <div
                className={`${styles.textLabel} ${styles.labelLeft} mb-1 mt-4`}
              >
                Email
              </div>
              <input
                type="text"
                className={`form-control ${styles.borderLogin} rounded-2`}
                id="username"
                placeholder="Usuario"
                value={form.username}
                onChange={(event) => setField("username", event.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="d-flex flex-row justify-content-between">
                <div
                  className={`${styles.textLabel} ${styles.labelLeft}  mb-1 mt-4`}
                >
                  Password
                </div>
                <div className="form-group align-items-center mb-1 mt-4">
                  <a href="#" className={`${styles.forgotText}`}>
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className={`input-group ${styles.passwordInputGroup}`}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className={`form-control ${styles.borderLogin} rounded-2 ${styles.passwordInput}`}
                  id="password"
                  placeholder="Contraseña"
                  value={form.password}
                  onChange={(event) => setField("password", event.target.value)}
                />
                <div
                  className={`input-group-text bg-white ${styles.borderLogin} ${styles.passwordToggle}`}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className={`btn text-white rounded-2 px-4 ${styles.loginButton} mt-3`}
              disabled={loading}
            >
              {loading ? "Chargement en cours..." : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
