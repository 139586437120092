import React from "react";

export default function AppPolitique() {
  return (
    <div>
      <h1>Politique de Confidentialité - Bring Me Senegal</h1>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h1 className="h4 mb-0">
                  Politique de Confidentialité - Bring Me Senegal
                </h1>
              </div>
              <div className="card-body">
                <p className="text-muted mb-4">
                  La première application qui propose divers services au Sénégal
                  !
                </p>
                {/* Informations Personnelles */}
                <div className="mb-4">
                  <h5 className="text-primary">Informations Personnelles</h5>
                  <div className="list-group">
                    <div className="list-group-item">
                      <h6 className="mb-1">Nom</h6>
                      <small className="text-muted">
                        Fonctionnement de l'application
                      </small>
                    </div>
                    <div className="list-group-item">
                      <h6 className="mb-1">Adresse e-mail</h6>
                      <small className="text-muted">
                        Fonctionnement de l'application
                      </small>
                    </div>
                    <div className="list-group-item">
                      <h6 className="mb-1">ID utilisateur</h6>
                      <small className="text-muted">
                        Fonctionnement de l'application
                      </small>
                    </div>
                    <div className="list-group-item">
                      <h6 className="mb-1">Adresse</h6>
                      <small className="text-muted">
                        Fonctionnement de l'application
                      </small>
                    </div>
                    <div className="list-group-item">
                      <h6 className="mb-1">Numéro de téléphone</h6>
                      <small className="text-muted">
                        Fonctionnement de l'application
                      </small>
                    </div>
                  </div>
                </div>
                {/* Position */}
                <div className="mb-4">
                  <h5 className="text-primary">Position</h5>
                  <div className="list-group">
                    <div className="list-group-item">
                      <h6 className="mb-1">Position exacte</h6>
                      <small className="text-muted">
                        Partagée avec les livreurs
                      </small>
                    </div>
                  </div>
                </div>
                {/* Informations Financières */}
                <div className="mb-4">
                  <h5 className="text-primary">Informations Financières</h5>
                  <div className="list-group">
                    <div className="list-group-item">
                      <h6 className="mb-1">Historique des achats</h6>
                      <small className="text-muted">Suivi des commandes</small>
                    </div>
                  </div>
                </div>
                {/* Sécurité */}
                <div className="alert alert-info mt-4">
                  <h5 className="text-primary">Pratiques de Sécurité</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-2">
                      ✓ Les données sont chiffrées lors de leur transfert
                    </li>
                    <li className="mb-2">✓ Connexion sécurisée</li>
                    <li>
                      ✓ Possibilité de demander la suppression des données
                    </li>
                  </ul>
                </div>
                {/* Contact */}
                <div className="mt-4">
                  <h5 className="text-primary">Contact</h5>
                  <p className="mb-0">
                    Pour toute question ou demande de suppression de données,
                    contactez-nous à :
                  </p>
                  <a
                    href="mailto:bringmesn@gmail.com"
                    className="btn btn-primary mt-2"
                  >
                    Nous contacter
                  </a>
                </div>
                {/* Procédure de Suppression des Données */}
                <div className="mt-4">
                  <h5 className="text-primary">
                    Procédure de Suppression des Données
                  </h5>
                  <p className="mb-0">
                    Pour demander la suppression de vos données, veuillez suivre
                    les étapes suivantes :
                  </p>
                  <ol>
                    <li>
                      Allez sur la page <strong>Compte</strong>.
                    </li>
                    <li>
                      Cliquez sur le bouton{" "}
                      <strong>Supprimer mon compte</strong>.
                    </li>
                  </ol>
                  <p className="text-muted">
                    Si vous avez des questions, n'hésitez pas à nous contacter.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
