import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import styles from "../components/styles/adminheader.module.css";
import { Link, useNavigate } from "react-router-dom";
import MyContext from "../../MyContext";
import usePermissions from "../../hooks/usePermissions";
import { useDispatch } from "react-redux";
import { logout as Logout } from "../../stores/features/userSlice";

function PopUp() {
  //   const permissions = parseInt(localStorage.getItem("permissions"));
  const permissions = usePermissions();
  return (
    <div style={{ width: "200px", height: "auto" }}>
      <Link className={styles.popupButton} to={"/admin/alerts/expiry"}>
        <div style={{ color: "#4B5563" }}>Expiry Alerts</div>
      </Link>
      <Link className={styles.popupButton} to={"/admin/alerts/inventory"}>
        <div style={{ color: "#4B5563" }}>Inventory Alerts</div>
      </Link>
      {permissions === 1 && (
        <Link className={styles.popupButton} to={"/admin/alerts/quantity"}>
          <div style={{ color: "#4B5563" }}>Quantity Alerts</div>
        </Link>
      )}
      {permissions === 1 && (
        <Link className={styles.popupButton} to={"/admin/alerts/subcategory"}>
          <div style={{ color: "#4B5563" }}>Subcategory Alerts</div>
        </Link>
      )}
      {permissions === 1 && (
        <Link className={styles.popupButton} to={"/admin/alerts/image"}>
          <div style={{ color: "#4B5563" }}>Image Alerts</div>
        </Link>
      )}
    </div>
  );
}

function ProfilePopup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    // localStorage.clear();
    // window.location.replace('/admin/login');
    dispatch(Logout());
    navigate("/admin/login", { replace: true });
  };

  return (
    <div style={{ width: "150px", height: "auto" }}>
      <div className={styles.popupButton} onClick={logout}>
        <div>Logout</div>
      </div>
    </div>
  );
}

export default function AdminHeader() {
  const permissions = usePermissions();
//   const permissions = parseInt(localStorage.getItem("permissions"));
  const [showPopup, setShowPopup] = React.useState(false);
  const [showProfilePopup, setShowProfilePopup] = React.useState(false);

  const { setSidebarOpen } = useContext(MyContext);

  return (
    <div>
      {showPopup && (
        <div
          className={styles.popUpModule}
          style={{
            position: "absolute",
            zIndex: "1000000",
            top: "60px",
            right: "3%",
          }}
        >
          <PopUp />
        </div>
      )}
      {showProfilePopup && (
        <div
          className={styles.popUpModule}
          style={{
            width: "130px",
            position: "absolute",
            zIndex: "1000000",
            top: "60px",
            right: "3%",
          }}
        >
          <ProfilePopup />
        </div>
      )}
      <div
        className={`${styles.container} w-100 d-flex flex-row justify-content-between align-items-center py-3 shadow-sm px-4`}
        style={{ backgroundColor: "#FFF" }}
      >
        <div
          className={styles.burger}
          onClick={() => {
            setSidebarOpen((prev) => !prev);
          }}
        >
          <FontAwesomeIcon icon={faBars} size={"xl"} />
        </div>

        <Link to={"/admin/dashboard"}>
          <img src={"/logo.png"} width={"140px"} alt={"Bringme logo"} />
        </Link>
        <div className={"d-flex align-items-center"}>
          <div
            className={"pe-3"}
            role={"button"}
            onClick={() => setShowPopup(!showPopup)}
          >
            {permissions !== 3 && (
              <FontAwesomeIcon icon={faBell} size={"xl"} color={"#DDDFE1"} />
            )}
          </div>
          <div
            className={`${styles.userCircle}`}
            onClick={() => setShowProfilePopup(!showProfilePopup)}
          >
            <FontAwesomeIcon icon={faUser} color={"#DDDFE1"} />
          </div>
        </div>
      </div>
    </div>
  );
}
