import React, { useEffect, useRef, useState } from "react";
import ShopHeader from "../components/header/header";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import { useLocation } from "react-router-dom";
import styles from "./products.module.css";
import PageTitle from "../components/page_title/page_title";
import DeliveryText from "../components/delivery_text/delivery_text";
import Cart from "../components/cart/cart";
import ProductCard from "../components/product_card/product_card";
import Loading from "../../admin/components/loading";
import ProductModal from "../components/product_modal/product_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Text from "../../components/ajonjolib/inputs/text/text";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";
import { useQuery } from "@tanstack/react-query";
import useDistrict from "../../hooks/useDistrict";
import { queryClient } from "../../App";

export default function ShopProducts() {
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [currentCategory, setCurrentCategory] = useState(state?.category);
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [form, setForm] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [refreshCart, setRefreshCart] = useState(0);
  const [loading, setLoading] = useState(false);
  const scRefs = useRef([]);
  const district = useDistrict();
  // const district = JSON.parse(localStorage.getItem("district"));
  const [isSticky, setIsSticky] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [scrollLock, setScrollLock] = useState(false);
  const [showLogin, setShowLogin] = useState(0);
  const [locked, setLocked] = useState(false);
  const [lockScrollSubcategory, setLockScrollSubcategory] = useState(false);

  // console.log({ scRefs });

  useEffect(() => {
    document.title = "Products | Bring Me";
  }, []);

  useEffect(() => {
    if (scrollLock) {
      setTimeout(function () {
        setScrollLock(false);
      }, 1500);
    }
  }, [scrollLock]);

  // Queries
  const subCategoryQuery = useQuery({
    queryKey: [
      "product-category-details",
      {
        id: `${district.warehouse_id}-${district.id}-${currentCategory?.id}`,
      },
    ],
    queryFn: async () => {
      // if (currentCategory?.id !== -1) {
      // }
      const { data } = await axiosInstance.get(
        `c/subcategory/?category_id=${currentCategory?.id}`
      );
      return data;
    },
  });

  // Queries
  const promoQuery = useQuery({
    queryKey: [
      {
        type: "promos",
        query: district.warehouse_id,
      },
    ],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `product/promos/?unlogged_warehouse=${district.warehouse_id}`
      );
      return data?.results;
    },
  });

  // console.log({ subCategoryQuery });

  // Queries
  // const productQuery = useQuery({
  //   queryKey: [
  //     {
  //       type: "product-details",
  //       query:
  //         district.warehouse_id + "-" + district.id + "-" + currentCategory?.id,
  //     },
  //   ],
  //   queryFn: async () => {
  //     if (currentCategory?.id !== -1) {
  //       const { data } = await axiosInstance.get(
  //         `subcategory/?category_id=${currentCategory?.id}`
  //       );
  //       setSubcategories(data.results);
  //       const subcategory_results = data.results;
  //       if (subcategory_results.length > 0) {
  //         setCurrentSubcategory(subcategory_results[0]);

  //         let mult_subcategoryQuery = "";
  //         subcategory_results.map((subcategory) => {
  //           mult_subcategoryQuery += `&mult_subcategory=${subcategory.id}`;
  //           return null;
  //         });

  //         const { data: new_response } = await axiosInstance.get(
  //           `product/?all_warehouse=${true}&combos=${true}&max_query=1000&shop=1&quantity=1&unlogged_warehouse=${
  //             district.warehouse_id
  //           }${mult_subcategoryQuery}&district_id=${district.id}`
  //         );

  //         const groupedProducts = subcategory_results.map((subcategory) => ({
  //           subcategory,
  //           products: [],
  //         }));

  //         new_response?.results.forEach((product) => {
  //           const subcategory = product.subcategory;
  //           const groupIndex = groupedProducts.findIndex(
  //             (g) => g.subcategory.id === subcategory.id
  //           );

  //           if (groupIndex !== -1) {
  //             groupedProducts[groupIndex].products.push(product);
  //           }
  //         });

  //         return groupedProducts;
  //       }
  //     } else {
  //       const { data } = await axiosInstance.get(
  //         `product/promos/?unlogged_warehouse=${district?.warehouse_id}`
  //       );

  //       return [
  //         {
  //           subcategory: {
  //             name: "Promotions",
  //             id: -1,
  //           },
  //           products: data.results,
  //         },
  //       ];
  //     }
  //   },
  // });

  // console.log({ productQuery });

  // const getProducts = async () => {
  //   axiosInstance
  //     .get(`subcategory/?category_id=${currentCategory?.id}`)
  //     .then((response) => {
  //       setSubcategories(response?.data.results);
  //       const subcategory_results = response?.data.results;
  //       if (subcategory_results.length > 0) {
  //         setCurrentSubcategory(subcategory_results[0]);

  //         let mult_subcategoryQuery = "";
  //         subcategory_results.map((subcategory) => {
  //           mult_subcategoryQuery += `&mult_subcategory=${subcategory.id}`;
  //           return null;
  //         });

  //         axiosInstance
  //           .get(
  //             `product/?all_warehouse=${true}&combos=${true}&max_query=1000&shop=1&quantity=1&unlogged_warehouse=${
  //               district.warehouse_id
  //             }${mult_subcategoryQuery}&district_id=${district.id}`
  //           )
  //           .then((new_response) => {
  //             const groupedProducts = subcategory_results.map(
  //               (subcategory) => ({
  //                 subcategory,
  //                 products: [],
  //               })
  //             );

  //             new_response?.data.results.forEach((product) => {
  //               const subcategory = product.subcategory;
  //               const groupIndex = groupedProducts.findIndex(
  //                 (g) => g.subcategory.id === subcategory.id
  //               );

  //               if (groupIndex !== -1) {
  //                 groupedProducts[groupIndex].products.push(product);
  //               }
  //             });

  //             setProducts(groupedProducts);

  //             setLoading(false);
  //           });
  //       }
  //     });
  // };

  // const getPromoProducts = async () => {
  //   setSubcategories([]);
  //   axiosInstance
  //     .get(`product/promos/?unlogged_warehouse=${district}`)
  //     .then((response) => {
  //       const groupedProducts = [
  //         {
  //           subcategory: {
  //             name: "Promotions",
  //             id: -1,
  //           },
  //           products: response?.data.results,
  //         },
  //       ];

  //       console.log(response?.data.results);
  //       setProducts(groupedProducts);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   if (currentCategory?.id > 0) {
  //     const response = getProducts();
  //   } else {
  //     const response = getPromoProducts();
  //   }
  // }, [currentCategory]);
  // console.log({ state });

  const scrollToSubcategory = (subcategory) => {
    const ref = scRefs.current[subcategory.id];
    console.log({ ref });
    if (ref) {
      setScrollLock(true);
      const headerHeight = 160 + 30; // Assuming the header height is 80 pixels
      ref.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
      window.scrollBy(0, -headerHeight);
      //ref.scrollIntoView({ behavior: 'instant' });
    }
  };

  useEffect(() => {
    if (subCategoryQuery?.data?.results && !lockScrollSubcategory) {
      scrollToSubcategory(subCategoryQuery?.data?.results);
    }
    if (lockScrollSubcategory) {
      setLockScrollSubcategory(false);
    }
  }, []);

  const checkQuantity = (newQuantity, oldQuantity) => {
    setLocked(true);
    if (!oldQuantity) oldQuantity = 0;
    console.log(newQuantity);
    if (newQuantity < 0 && quantity === 1) return;

    let prevQtyProd = cartItems?.find(
      (item) => item.product.id === selectedProduct.id
    )?.qty;

    if (!prevQtyProd) prevQtyProd = 0;

    axiosInstance
      .get(`product/${selectedProduct.id}/count/${district.id}/`)
      .then((response) => {
        const foundQuantity = response?.data.quantity;

        if (foundQuantity >= prevQtyProd + (oldQuantity + newQuantity)) {
          setQuantity((prevQuantity) => prevQuantity + newQuantity);
        } else {
          toast(
            `Quantité maximale (${foundQuantity}) ajoutée au panier!`,
            ToastTypes.ERROR
          );
        }
        setLocked(false);
      });
  };

  const addToCart = (callBack) => {
    let previousQuantity = cartItems
      ? cartItems
          ?.filter((item) => item.product.id === selectedProduct.id)
          .map((item) => item.qty)[0]
      : 0;
    // console.log(previousQuantity);
    // Add to Cart
    axiosInstance
      .post(`cart/add/${selectedProduct.id}/`, {
        quantity: quantity,
        district_id: district.id,
      })
      .then((response) => {
        if (response?.response?.status === 500) {
          setShowLogin((prev) => prev + 1);
        }
        setShowModal(false);

        setRefreshCart((prev) => prev + 1);

        const data = response?.data;
        let found = false;
        data?.items
          ?.filter((item) => item.product.id === selectedProduct.id)
          .map((item) => {
            found = true;
          });
        if (!found) {
          toast("Quantité maximale ajoutée au panier!", ToastTypes.ERROR);
        }

        let found2 = false;
        const productIds = response?.data?.items.map((item) => {
          return {
            product_id: item.product.id,
            quantity: item.qty,
          };
        });

        productIds
          ?.filter((item) => item.product_id === selectedProduct.id)
          .map((item) => {
            found2 = true;
            if (previousQuantity === item.quantity) {
              //setShowError(true);
              toast("Quantité maximale ajoutée au panier!", ToastTypes.ERROR);
            }
            //setQuantity(item.quantity)
          });
        setQuantity(1);
        callBack();
      });
  };

  useEffect(() => {
    if (!showModal) {
      setQuantity(1);
    }
  }, [showModal]);

  // const handleScroll = () => {
  //   const scrollY = window.scrollY;
  //   setIsSticky(scrollY > 0);

  //   if (scrollLock) return;

  //   const visibleSubcategory = subCategoryQuery?.data?.results.find(
  //     (subcategory) => {
  //       const ref = scRefs.current[subcategory.id];
  //       if (ref) {
  //         const rect = ref.getBoundingClientRect();
  //         return (
  //           rect.top <= window.innerHeight / 2 &&
  //           rect.bottom >= window.innerHeight / 2
  //         );
  //       }
  //       return false;
  //     }
  //   );

  //   if (visibleSubcategory && currentSubcategory !== visibleSubcategory) {
  //     setLockScrollSubcategory(true);
  //     setCurrentSubcategory(visibleSubcategory);
  //   }
  // };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsSticky(scrollY > 0);

    if (scrollLock) return;

    const visibleSubcategory = subCategoryQuery?.data?.results.find(
      (subcategory) => {
        const ref = scRefs.current[subcategory.id];
        if (ref) {
          const rect = ref.getBoundingClientRect();
          return (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          );
        }
        return false;
      }
    );

    if (visibleSubcategory && currentSubcategory !== visibleSubcategory) {
      setLockScrollSubcategory(true);
      setCurrentSubcategory(visibleSubcategory);

      const subcategoryItem = document.querySelector(
        `[data-subcategory-id="${visibleSubcategory.id}"]`
      );

      if (subcategoryItem) {
        subcategoryItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const debouncedHandleScroll = (() => {
    let timeoutId;

    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 200);
    };
  })();

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [subCategoryQuery?.data?.results, currentSubcategory, scrollLock]);

  // console.log({ promoQuery });

  const handleMouseDown = async (data) => {
    const queryKey = [
      "product-category-details",
      {
        id: `${district.warehouse_id}-${district.id}-${data?.id}`,
      },
    ];

    // Vérification des données dans le cache
    const cachedData = queryClient.getQueryData(queryKey);

    try {
      if (!cachedData) {
        console.log("Préfetching en cours...");
        await queryClient.prefetchQuery({
          queryKey, // Utilisation de la même clé pour cohérence
          queryFn: async () => {
            const response = await axiosInstance.get(
              `c/subcategory/?category_id=${data?.id}`
            );
            return response.data;
          },
        });
        console.log("Préfetching terminé avec succès !");
      } else {
        console.log("Les données sont déjà en cache, pas de préfetching.");
      }
    } catch (error) {
      console.error("Erreur lors du préfetching :", error);
    }
  };

  return (
    <div>
      <ShopHeader
        showLogin={showLogin}
        noMenu={{ name: "Epicerie", url: "/categories" }}
      />
      <Cart refreshCart={refreshCart} outCartItems={setCartItems} />

      <div>
        <ProductModal
          locked={locked}
          quantity={quantity}
          setShowModal={setShowModal}
          showModal={showModal}
          selectedProduct={selectedProduct}
          addToCart={addToCart}
          checkQuantity={checkQuantity}
        />
      </div>

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <div className={`${isSticky ? styles.stickyCategoryBar : ""}`}>
          <div className={`${styles.scrollable}`}>
            {state?.categories.map((category, index) => (
              <div
                className={`px-3 py-2 ${styles.scrollableItem} ${
                  currentCategory?.id === category.id && styles.selected
                }`}
                onClick={() => {
                  setCurrentCategory(category);
                  setCurrentSubcategory(null);
                  // console.log({ category });
                }}
                onMouseDown={() => handleMouseDown(category)}
              >
                <div>{category.name}</div>
              </div>
            ))}
          </div>

          <div className={styles.scrollable}>
            {subCategoryQuery?.data?.results?.map((subcategory, index) => (
              <div
                className={`px-3 py-2 ${styles.scrollableItem} ${
                  index === 0 && !currentSubcategory?.id
                    ? styles.selected
                    : currentSubcategory?.id === subcategory.id &&
                      styles.selected
                }`}
                // onClick={() => setCurrentSubcategory(subcategory)}
                onClick={() => {
                  setCurrentSubcategory(subcategory);
                  scrollToSubcategory(subcategory);
                }}
                data-subcategory-id={subcategory.id}
              >
                <div>{subcategory.name}</div>
              </div>
            ))}
          </div>
        </div>
        <PageTitle>
          <div
            className={"d-flex justify-content-center mt-2 align-items-center"}
          >
            <div className={`${styles.responsiveSearch} me-2`}>
              <Text
                style={{ width: "100%" }}
                value={form["query"]}
                onChange={(e) => setForm({ ...form, query: e })}
                placeholder={"Recherchez vos produits à partir d'ici"}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faX}
                size={"lg"}
                color={"#00A6A8"}
                onClick={() => setForm({ ...form, query: "" })}
              />
            </div>
          </div>
        </PageTitle>
        <div className={`${styles.responsiveContainer} pb-5`}>
          <DeliveryText />

          {+currentCategory?.id === -1 ? (
            <div className={"mb-4"} key={currentCategory?.id}>
              <div className={"pb-1 fw-bold fs-2 text-start"}>
                {"Promotions"}
              </div>
              <div className={`${styles.responsiveFlexWarehouse}`}>
                {promoQuery?.isLoading ? (
                  <Loading />
                ) : (
                  promoQuery?.data && (
                    <ProductPromotionGroupList
                      data={promoQuery?.data || []}
                      formQuery={form["query"]}
                      setShowLogin={setShowLogin}
                      cartItems={cartItems}
                      setRefreshCart={setRefreshCart}
                      setSelectedProduct={setSelectedProduct}
                      setShowModal={setShowModal}
                    />
                  )
                )}
              </div>
            </div>
          ) : subCategoryQuery.isLoading ? (
            <Loading />
          ) : subCategoryQuery?.data &&
            subCategoryQuery?.data?.results.length === 0 ? (
            <p>Pas de sous categorie</p>
          ) : subCategoryQuery?.data?.results.length > 0 ? (
            subCategoryQuery?.data?.results.map((subCategory) => (
              <div
                className={"mb-4"}
                key={subCategory?.id}
                ref={(ref) => (scRefs.current[subCategory.id] = ref)}
              >
                <div className={"pb-1 fw-bold fs-2 text-start"}>
                  {subCategory.name}
                </div>
                <div className={`${styles.responsiveFlexWarehouse}`}>
                  <ProductGroupList
                    district={district}
                    categoryId={subCategory?.id}
                    formQuery={form["query"]}
                    setShowLogin={setShowLogin}
                    cartItems={cartItems}
                    setRefreshCart={setRefreshCart}
                    setSelectedProduct={setSelectedProduct}
                    setShowModal={setShowModal}
                  />
                </div>
              </div>
            ))
          ) : (
            "Pas de donnees"
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

const ProductGroupList = ({
  categoryId,
  formQuery,
  setShowLogin,
  cartItems,
  setRefreshCart,
  setSelectedProduct,
  setShowModal,
  district,
}) => {
  const productQuery = useQuery({
    queryKey: ["products", { query: categoryId + " - " + district?.id }],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        // `c/products/?all_warehouse=${true}&combos=${true}&max_query=1000&shop=1&quantity=1&unlogged_warehouse=${
        `product/?all_warehouse=${true}&combos=${true}&max_query=1000&shop=1&quantity=1&unlogged_warehouse=${
          district.warehouse_id
        }&mult_subcategory=${categoryId}&district_id=${district.id}`
      );
      return data;
    },
  });

  if (productQuery.isLoading) {
    return <Loading />;
  }

  if (productQuery.isError) {
    return <div>Erreur lors du chargement des produits.</div>;
  }

  const productData = productQuery.data;

  // console.log({ productData });

  if (!productData || productData.length === 0) {
    return <div>Pas de produits</div>;
  }

  return productData?.results
    .filter((product) =>
      product.name
        .toLowerCase()
        .includes(formQuery ? formQuery.toLowerCase() : "")
    )
    .map((product) => (
      <div
        key={product.id}
        className={`d-flex mb-2 ${styles.responsiveProductWarehouse}`}
      >
        <ProductCard
          setShowLogin={setShowLogin}
          cartItems={cartItems}
          product={product}
          setRefreshCart={setRefreshCart}
          onClick={() => {
            setSelectedProduct(product);
            setShowModal(true);
          }}
        />
      </div>
    ));
};

const ProductPromotionGroupList = ({
  formQuery,
  setShowLogin,
  cartItems,
  setRefreshCart,
  setSelectedProduct,
  setShowModal,
  data,
}) => {
  if (!data || data.length === 0) {
    return <div>Pas de produits</div>;
  }

  return data
    .filter((product) =>
      product.name
        .toLowerCase()
        .includes(formQuery ? formQuery.toLowerCase() : "")
    )
    .map((product) => (
      <div
        key={product.id}
        className={`d-flex mb-2 ${styles.responsiveProductWarehouse}`}
      >
        <ProductCard
          setShowLogin={setShowLogin}
          cartItems={cartItems}
          product={product}
          setRefreshCart={setRefreshCart}
          onClick={() => {
            setSelectedProduct(product);
            setShowModal(true);
          }}
        />
      </div>
    ));
};
