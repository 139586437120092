import React, { useEffect, useMemo, useState } from "react";
import ShopHeader from "../components/header/header";
import PageTitle from "../components/page_title/page_title";
import styles from "./billers.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeliveryText from "../components/delivery_text/delivery_text";
import Cart from "../components/cart/cart";
import Footer from "../components/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPersonBiking, faX } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../AxiosInstance";
import useDistrict from "../../hooks/useDistrict";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../admin/components/loading";
import { filterHomeProductData } from "../../utils/utils";
import Text from "../../components/ajonjolib/inputs/text/text";
import { queryClient } from "../../App";

export default function ShopBillers() {
  const { state } = useLocation();
  // const [section, setSection] = useState({});
  const navigate = useNavigate();
  const { sectionId: sectionName } = useParams();
  // const [billers, setBillers] = useState([]);
  const [form, setForm] = useState({ query: "" });
  const foundDistrict = useDistrict();

  const sectionId = useMemo(() => {
    return sectionName === "commerces" ? 8 : 7;
  }, [sectionName]);

  useEffect(() => {
    document.title = `${
      state?.title || +sectionId === 7
        ? "Restaurants et autres"
        : "Nos Commerces"
    } | Bring Me`;

    // if (state?.data) {
    //   setSection(state?.data[0].section);
    //   // document.title = `Tout les billers | Bring Me`;
    //   setBillers(state?.data);
    // } else {
    //   const currentUrl = window.location.href;
    //   const splitURL = currentUrl.split("/");
    //   // const foundDistrict = JSON.parse(localStorage.getItem("district"));
    //   if (splitURL[splitURL.length - 1] === "restaurants-et-autres") {
    //     axiosInstance
    //       .get(`supplier/?section_id=7&district_id=${foundDistrict.id}`)
    //       .then((response) => {
    //         if (response.data) {
    //           const filteredData = response?.data.filter(
    //             (item) => item.fee !== undefined
    //           );
    //           filteredData.sort((a, b) => a.fee - b.fee);
    //           filteredData.sort((a, b) => b.is_open - a.is_open);
    //           setBillers(filteredData);
    //         }
    //       });
    //   } else {
    //     axiosInstance
    //       .get(`supplier/?section_id=8&district_id=${foundDistrict.id}`)
    //       .then((response) => {
    //         if (response.data) {
    //           const filteredData = response.data.filter(
    //             (item) => item.fee !== undefined
    //           );
    //           filteredData.sort((a, b) => a.fee - b.fee);
    //           filteredData.sort((a, b) => b.is_open - a.is_open);
    //           setBillers(filteredData);
    //         }
    //       });
    //   }
    // }
  }, []);

  // Queries
  const dataQuery = useQuery({
    queryKey: [
      "restaurants",
      { query: foundDistrict?.id, id: sectionId || state?.id },
    ],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `c/suppliers/?section_id=${sectionId || state?.id}&district_id=${
          foundDistrict?.id
        }`
      );

      return data;
    },
    initialData: state?.data || [],
  });

  const handleChange = (e) => {
    setForm({ ...form, query: e });
  };

  const handleMouseDown = async (data) => {
    // console.log("start");

    try {
      // alert(data.slug); // Affiche le slug pour vérifier l'interaction utilisateur
      if (!queryClient.getQueryData(["product-details", { id: data?.id }])) {
        await queryClient.prefetchQuery({
          queryKey: ["product-details", { id: data?.id }],
          queryFn: async () => {
            const response = await axiosInstance.get(
              `/subcategory/?biller_id=${data?.id}`
            );
            return response.data?.results;
          },
        });
        console.log("Préfetching terminé avec succès !");
      }
    } catch (error) {
      console.error("Erreur lors du préfetching :", error);
    }
  };

  return (
    <div>
      <ShopHeader />
      <Cart />

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle
          name={
            state?.title
              ? state?.title
              : sectionName === "commerces"
              ? "Nos Commerces"
              : "Restaurants et autres"
          }
          description={""}
        >
          <div
            className={"d-flex justify-content-center mt-2 align-items-center"}
          >
            <div className={`${styles.responsiveSearch} me-2`}>
              <Text
                style={{ width: "100%" }}
                value={form["query"]}
                onChange={handleChange}
                placeholder={"Recherchez des etablissements à partir d'ici"}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faX}
                size={"lg"}
                color={"#00A6A8"}
                onClick={() => setForm({ ...form, query: "" })}
              />
            </div>
          </div>
        </PageTitle>
        <div className={`${styles.responsiveContainer}`}>
          <DeliveryText />

          <div
            className={`d-flex flex-wrap mt-4 justify-content-between`}
            style={{ display: "flex" }}
          >
            {dataQuery?.isLoading ? (
              <Loading />
            ) : !state?.data && dataQuery?.isFetching ? (
              <Loading />
            ) : (
              filterHomeProductData(dataQuery?.data)
                ?.filter((biller) =>
                  biller?.name
                    .toLowerCase()
                    ?.includes(form?.query?.toLowerCase())
                )
                ?.map((biller, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex-fill mb-2 pb-1 ${styles.billerBox}`}
                      style={{ flex: "1", marginLeft: "10px" }}
                      onClick={() => {
                        navigate(`/${biller.slug}`, { state: biller });
                      }}
                      onMouseDown={() => handleMouseDown(biller)}
                    >
                      <div style={{ position: "relative" }}>
                        {!biller.is_open && (
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 2,
                              borderTopLeftRadius: "15px",
                              borderTopRightRadius: "15px",
                              userSelect: "none",
                            }}
                          >
                            <div
                              style={{ backgroundColor: "#009F7F" }}
                              className={
                                "d-flex px-3 py-1 align-items-center rounded-3"
                              }
                            >
                              <div className={"pe-2"}>
                                <FontAwesomeIcon icon={faLock} color={"#FFF"} />
                              </div>
                              <div style={{ color: "#FFF", fontWeight: 500 }}>
                                Fermé
                              </div>
                            </div>
                          </div>
                        )}
                        <img
                          alt={"biller_image"}
                          src={biller?.image?.image}
                          width={"100%"}
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            maxHeight: "108px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className={"px-2"}>
                        <div style={{ fontWeight: "500", fontSize: "x-large" }}>
                          {biller.name}
                        </div>
                        <div className={"d-flex align-items-center"}>
                          <FontAwesomeIcon
                            icon={faPersonBiking}
                            size={"xs"}
                            className={"pe-1"}
                          />
                          <div style={{ fontSize: "14px" }}>
                            {biller.delivery_time} • {biller.fee}Fr
                          </div>
                        </div>
                        <div>{biller.description}</div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
