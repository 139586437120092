// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories_title__EJ\\+xc {
  background-color: #ffffff;
  font-weight: 600;
  font-size: x-large;
  padding: 7px 0;
}

.categories_deliveryText__Db8Oy {
  margin-top: 1rem;
  display: flex;
  font-weight: 500;
  font-size: x-large;
}

.categories_category__yjQS8 {
  max-width: 160px;
  background-color: #fff;
  border: rgba(0, 0, 0, 0.125) 1px solid;
  border-radius: 0.25rem;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  margin-right: 2px;
  margin-bottom: 2px;
}

.categories_category__yjQS8 img {
  height: 100%;
  margin-top: -10px;
}

.categories_responsiveContainer__1N-z- {
  margin-left: 3rem;
  margin-right: 3rem;
}

.categories_responsiveSearch__cQoXs {
  flex-basis: 40%;
}

@media (max-width: 650px) {
  .categories_categoryContainer__Nx3Pr {
    flex-basis: 33%;
  }

  .categories_responsiveSearch__cQoXs {
    flex-basis: 80%;
  }

  .categories_responsiveContainer__1N-z- {
    margin-right: 0.4rem;
    margin-left: 0.4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shop/categories/categories.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,sCAAsC;EACtC,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,oBAAoB;IACpB,mBAAmB;EACrB;AACF","sourcesContent":[".title {\n  background-color: #ffffff;\n  font-weight: 600;\n  font-size: x-large;\n  padding: 7px 0;\n}\n\n.deliveryText {\n  margin-top: 1rem;\n  display: flex;\n  font-weight: 500;\n  font-size: x-large;\n}\n\n.category {\n  max-width: 160px;\n  background-color: #fff;\n  border: rgba(0, 0, 0, 0.125) 1px solid;\n  border-radius: 0.25rem;\n  cursor: pointer;\n  height: auto;\n  overflow: hidden;\n  margin-right: 2px;\n  margin-bottom: 2px;\n}\n\n.category img {\n  height: 100%;\n  margin-top: -10px;\n}\n\n.responsiveContainer {\n  margin-left: 3rem;\n  margin-right: 3rem;\n}\n\n.responsiveSearch {\n  flex-basis: 40%;\n}\n\n@media (max-width: 650px) {\n  .categoryContainer {\n    flex-basis: 33%;\n  }\n\n  .responsiveSearch {\n    flex-basis: 80%;\n  }\n\n  .responsiveContainer {\n    margin-right: 0.4rem;\n    margin-left: 0.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `categories_title__EJ+xc`,
	"deliveryText": `categories_deliveryText__Db8Oy`,
	"category": `categories_category__yjQS8`,
	"responsiveContainer": `categories_responsiveContainer__1N-z-`,
	"responsiveSearch": `categories_responsiveSearch__cQoXs`,
	"categoryContainer": `categories_categoryContainer__Nx3Pr`
};
export default ___CSS_LOADER_EXPORT___;
