import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import usePermissions from "../../../hooks/usePermissions";
import useWarehouse from "../../../hooks/useWarehouse";

export default function InventoryAlerts() {
  const [rows, setRows] = useState([]);
  // const permissions = parseInt(localStorage.getItem('permissions'));
  // const warehouse_id = localStorage.getItem('warehouse_id');
  const permissions = usePermissions();
  const warehouse_id = useWarehouse();
  const defaultFilters =
    permissions !== 1 ? { warehouse_id: warehouse_id } : {};

  const inventoryObj = (id, warehouse_id) => {
    axiosInstance
      .put(`product/update_inventoried_date/`, {
        id: id,
        warehouse: warehouse_id,
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const cols = [
    {
      title: "Product",
      internal: "product_name",
      filter: true,
      filterInternal: "product",
    },
    {
      title: "Warehouse",
      internal: "warehouse_name",
      filter: true,
      filterInternal: "warehouse",
    },
    {
      title: "Quantity",
      internal: "quantity",
      filter: true,
      filterInternal: "quantity",
    },
    {
      title: "Last Inventoried",
      internal: "inventoried_date",
      code: (data, row) => {
        return new Date(row).toLocaleDateString();
      },
    },
    {
      title: "Inventoried Date",
      internal: "inventoried_date",
      code: (data, row) => {
        const last_inventoried = new Date(row);
        const days = 30;
        const next_inventoried = new Date(
          last_inventoried.getTime() + days * 24 * 60 * 60 * 1000
        );
        const current_date = new Date();
        return (
          <div>
            {current_date > next_inventoried ? (
              <div
                style={{
                  backgroundColor: "#d9534F",
                  borderRadius: "6px",
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "large",
                }}
              >
                {next_inventoried.toLocaleDateString()}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#d9a94f",
                  borderRadius: "6px",
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "large",
                }}
              >
                {next_inventoried.toLocaleDateString()}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      internal: "product_id",
      code: (row, data) => {
        return (
          <div
            className={"d-flex justify-content-between"}
            style={{ float: "right" }}
          >
            <div
              role={"button"}
              onClick={() => inventoryObj(row.product_id, row.warehouse_id)}
            >
              <FontAwesomeIcon icon={faCalendar} color={"#9EA3AC"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4 pb-5`}>
        <TableView
          defaultFilters={defaultFilters}
          fetchUrl={"stock/inventory_alerts"}
          pull={setRows}
          title={"Inventory Alerts"}
          cols={cols}
          rows={rows}
          noCreate={true}
        />
      </div>
    </div>
  );
}
