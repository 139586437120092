import React, { createContext, useState, useEffect, useContext } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleGlobalError = (event) => {
      setError(event.detail);
      console.log("error herre***********************************8");
    };

    window.addEventListener("globalError", handleGlobalError);

    return () => {
      window.removeEventListener("globalError", handleGlobalError);
    };
  }, []);

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
