import React, { useEffect, useRef, useState } from "react";
import styles from "./shop_sidebar.module.css";
import { faQuestionCircle, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import LoginPopup from "../login_popup/login_popup";
import { useDispatch } from "react-redux";
import useAuthenticate from "../../../hooks/useAuthenticate";
import { logout as setLogout } from "../../../stores/features/userSlice";
import useGetUser from "../../../hooks/useGetUser";

export default function ShopSidebar({ show, setter, showLogin }) {
  let authenticated = useAuthenticate();
  const [showModal, setShowModal] = useState(false);
  const componentRef = useRef(null);
  // const [user, setUser] = useState({});

  const dispatch = useDispatch();

  const user = useGetUser()

  useEffect(() => {
    function handleClickOutside(event) {
      const currWidth = componentRef.current.clientWidth;
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        currWidth > 0
      ) {
        setter(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const logout = () => {
    // localStorage.clear();
    // axiosInstance.defaults.headers.common["Authorization"] = null;
    // window.location.replace("/locate");
    dispatch(setLogout());
    navigate("/locate", { replace: true });
  };

  const itemsTop = [];

  const itemsBottom = [
    { name: "Contact", url: "/contact" },
    { name: "Profil", url: "/profile" },
    { name: "Mes commandes", url: "/my_orders" },
    { name: "Déconnexion", func: logout },
    { name: "Assistance: 78 116 92 92", icon: faQuestionCircle, url: "/" },
  ];

  const navigate = useNavigate();

  const loginOrProfile = () => {
    if (authenticated) {
      navigate("/profile/");
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (showLogin > 0) {
      setShowModal(true);
    }
  }, [showLogin]);

  // useEffect(() => {
  //   axiosInstance.get("current_user/").then((response) => {
  //     if (response?.response?.status === 500) {
  //       localStorage.removeItem("access_token");
  //     }
  //     if (response.status === 200) {
  //       setUser(response.data);
  //     }
  //   });
  // }, []);

  return (
    <div
      className={`${styles.outContainer} ${!show && styles.hide}`}
      ref={componentRef}
    >
      <LoginPopup show={showModal} setShow={setShowModal} />

      <div
        className={"d-flex ms-5 mt-5"}
        role={"button"}
        onClick={() => setter(false)}
      >
        <FontAwesomeIcon style={{ zIndex: "9999" }} icon={faX} size={"lg"} />
      </div>
      <div className={`${styles.container}`}>
        <div>
          {itemsTop.map((item, index) => (
            <div
              key={index}
              className={"d-flex my-1 py-2 mx-5"}
              style={{ textAlign: "left" }}
              role={"button"}
              onClick={() => {
                if (!authenticated) {
                  setShowModal(true);
                  return;
                }
                navigate(item.url);
              }}
            >
              <FontAwesomeIcon icon={item.icon} className={"pt-1 pe-2"} />
              <div>{item.name}</div>
            </div>
          ))}

          <div className={styles.userBox} onClick={() => loginOrProfile()}>
            <div className={styles.userButton}>
              {authenticated
                ? `Bonjour ${user?.data?.user_data?.name}`
                : "Se connecter"}
            </div>
          </div>

          <div className={"d-flex mx-5"} style={{ color: "#009d7e" }}>
            <b>
              {authenticated
                ? `${user?.data?.user_data?.points} Points Utilisables`
                : null}
            </b>
          </div>

          {itemsBottom.map((item, index) => (
            <div
              key={index}
              className={"d-flex my-1 py-2 mx-5"}
              style={{ textAlign: "left" }}
              role={"button"}
              onClick={() => {
                if (!authenticated) {
                  setShowModal(true);
                  return;
                }
                if (item.url) navigate(item.url);
                else item.func();
              }}
            >
              <FontAwesomeIcon icon={item.icon} className={"pt-1 pe-2"} />
              <div>{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
