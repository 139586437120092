import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import userIdReducer from "./features/userSlice";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: "WEB_BRING_ME",
  storage,
};

const rootReducer = combineReducers({
  persist: persistReducer(
    persistConfig,
    combineReducers({ user: userIdReducer })
  ),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
