import React, { createContext, useEffect, useState } from "react";
import axiosInstance from "../AxiosInstance";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDistrict } from "../stores/features/userSlice";
import useDistrict from "../hooks/useDistrict";

export const MapContext = createContext();

function MapProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const district = useDistrict();

  const [allowedLocation, setAllowedLocation] = useState(false);
  const [location, setLocation] = useState(null);

  const getAddresValidation = async (latitude, longitude) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBZmhQsod4pJDQvuctemtOfYnaRzOMsttg`
    );
    const data = await response.json();

    if (data.results.length > 0) {
      return data.results[0].formatted_address;
    }

    return null;
  };

  const localizeMe = (override, callBack) => {
    console.log("localizeMe");
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setAllowedLocation(true);
        // setForm({
        //   ...form,
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // });
        // findAddress(
        //   position.coords.latitude,
        //   position.coords.longitude,
        //   override
        // );
        // console.log({ position });

        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        if (callBack) {
          return callBack(position.coords.latitude, position.coords.longitude);
        }

        const address = await getAddresValidation(
          position.coords.latitude,
          position.coords.longitude
        );

        if (address && address !== district?.addressResponse) {
          const { data } = await axiosInstance.post(`set_location/`, {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address,
          });

          // console.log({ data });

          if (!data) {
            navigate("/locate", { replace: true });
            return;
          }
          // console.log({ data });

          dispatch(
            setDistrict({
              ...data,
              addressResponse: address,
              form: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
          );

          if (window.location.pathname === "/locate") {
            navigate("/", { replace: true });
          } else if (window.location.pathname === "/checkout") {
            navigate("/", { replace: true });
          }
        } else {
          console.log("We are on the same location");
        }
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          alert(
            'La localisation est bloquée. Veuillez autoriser l\'accès à la localisation pour utiliser "me localiser".'
          );
        } else {
          alert(
            "Une erreur est survenue lors de la tentative de localisation."
          );
        }
      },
      { enableHighAccuracy: true }
    );
  };

  useEffect(() => {
    localizeMe();
  }, []);

  return (
    <MapContext.Provider
      value={{ localizeMe, location, allowedLocation, getAddresValidation }}
    >
      {children ? children : <Outlet />}
    </MapContext.Provider>
  );
}

export default MapProvider;
