// import React, { useState, useEffect } from "react";
// import axiosInstance from "../../../AxiosInstance";
// import { Pagination } from "react-bootstrap";
// import { parseAsInteger, useQueryState } from "nuqs";

// export default function PaginationAdmin({
//   prop,
//   func,
//   limit,
//   defaultFilters,
//   onPageChange,
// }) {
//   const paginationLimit = limit;
//   const [last, setLast] = useState(0);
//   const [next, setNext] = useState("");
//   const [prev, setPrev] = useState("");
//   const [filters, setFilters] = useState({ ...defaultFilters });
//   const [timer, setTimer] = useState(null);
//   const [active, setActive] = useQueryState("active", parseAsInteger.withDefault(1));

//   useEffect(() => {
//     if (timer) {
//       clearTimeout(timer);
//     }

//     const newTimer = setTimeout(() => {
//       if (func === undefined) return;

//       const queryParams = Object.entries(filters)
//         .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
//         .join("&");

//       const url = `${prop}/?${queryParams}`;

//       axiosInstance.get(url).then((response) => {
//         setNext(response?.data?.next);
//         setPrev(response?.data?.prev);
//         setLast(Math.ceil(response?.data.count / paginationLimit));
//         if (typeof response?.data?.results !== "undefined")
//           func(response?.data?.results);
//         else func(response?.data);
//       });
//     }, 400);

//     setTimer(newTimer);
//   }, [filters]);

//   const paginationGo = (page) => {
//     setActive(page);
//     if (onPageChange) {
//       onPageChange(page); // Appelle la fonction pour React Query
//     } else {
//       const queryParams = Object.entries(filters)
//         .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
//         .join("&");

//       axiosInstance
//         .get(
//           prop +
//             `/?${queryParams}&limit=` +
//             paginationLimit +
//             "&offset=" +
//             paginationLimit * (page - 1) +
//             "&page=" +
//             page
//         )
//         .then((response) => {
//           if (typeof response?.data.results !== "undefined")
//             func(response?.data.results);
//           else func(response?.data);
//           setNext(response?.data.next);
//           setPrev(response?.data.prev);
//           // setActive(page);
//         });
//     }
//   };

//   return (
//     <Pagination style={{ paddingTop: "10px", float: "right" }}>
//       {active > 3 && <Pagination.Ellipsis />}
//       {active > 2 && (
//         <Pagination.Item onClick={() => paginationGo(active - 2)}>
//           {active - 2}
//         </Pagination.Item>
//       )}
//       {active > 1 && (
//         <Pagination.Item onClick={() => paginationGo(active - 1)}>
//           {active - 1}
//         </Pagination.Item>
//       )}
//       <Pagination.Item active>{active}</Pagination.Item>
//       {active < last - 0 && (
//         <Pagination.Item onClick={() => paginationGo(active + 1)}>
//           {active + 1}
//         </Pagination.Item>
//       )}
//       {active < last - 1 && (
//         <Pagination.Item onClick={() => paginationGo(active + 2)}>
//           {active + 2}
//         </Pagination.Item>
//       )}
//       {active < last - 2 && <Pagination.Ellipsis />}
//     </Pagination>
//   );
// }

import React, { useState, useEffect } from "react";
import axiosInstance from "../../../AxiosInstance";
import { Pagination } from "react-bootstrap";

export default function PaginationAdmin({
  prop,
  func,
  limit,
  defaultFilters,
  onPageChange,
}) {
  const paginationLimit = limit;
  const [last, setLast] = useState(0);
  const [active, setActive] = useState(1);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      if (func === undefined) return;

      const queryParams = Object.entries(filters)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      const url = `${prop}/?${queryParams}`;

      axiosInstance.get(url).then((response) => {
        setNext(response?.data?.next);
        setPrev(response?.data?.prev);
        setLast(Math.ceil(response?.data.count / paginationLimit));
        if (typeof response?.data?.results !== "undefined")
          func(response?.data?.results);
        else func(response?.data);
      });
    }, 400);

    setTimer(newTimer);
  }, [filters]);

  const paginationGo = (page) => {
    setActive(page);
    if (onPageChange) {
      onPageChange(page); // Appelle la fonction pour React Query
    } else {
      const queryParams = Object.entries(filters)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      axiosInstance
        .get(
          prop +
            `/?${queryParams}&limit=` +
            paginationLimit +
            "&offset=" +
            paginationLimit * (page - 1) +
            "&page=" +
            page
        )
        .then((response) => {
          if (typeof response?.data.results !== "undefined")
            func(response?.data.results);
          else func(response?.data);
          setNext(response?.data.next);
          setPrev(response?.data.prev);
          // setActive(page);
        });
    }
  };

  return (
    <Pagination style={{ paddingTop: "10px", float: "right" }}>
      {active > 3 && <Pagination.Ellipsis />}
      {active > 2 && (
        <Pagination.Item onClick={() => paginationGo(active - 2)}>
          {active - 2}
        </Pagination.Item>
      )}
      {active > 1 && (
        <Pagination.Item onClick={() => paginationGo(active - 1)}>
          {active - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active>{active}</Pagination.Item>
      {active < last - 0 && (
        <Pagination.Item onClick={() => paginationGo(active + 1)}>
          {active + 1}
        </Pagination.Item>
      )}
      {active < last - 1 && (
        <Pagination.Item onClick={() => paginationGo(active + 2)}>
          {active + 2}
        </Pagination.Item>
      )}
      {active < last - 2 && <Pagination.Ellipsis />}
    </Pagination>
  );
}
