import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import useStoreVersion from "./useStoreVersion";
import { setCredentials, setDistrict } from "../stores/features/userSlice";

function useApdateApp() {
  const district = JSON.parse(localStorage.getItem("district") || null);
  const access_token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  const permissions = parseInt(localStorage.getItem("permissions"));
  const warehouse_id = parseInt(localStorage.getItem("warehouse_id"));
  const biller_id = parseInt(localStorage.getItem("biller_id"));
  const dispatch = useDispatch();

  const storeVersion = useStoreVersion();

  useLayoutEffect(() => {
    if (!storeVersion && access_token) {
      dispatch(
        setCredentials({
          access_token,
          refresh_token,
          permissions,
          warehouse_id,
          biller_id,
        })
      );
      if (district) {
        dispatch(setDistrict(district));
      }
    }
  }, []);

  return storeVersion ? "App apdated" : "App not updated";
}

export default useApdateApp;
