import React from "react";
import useDistrict from "../../../hooks/useDistrict";
import { Link } from "react-router-dom";

export default function DeliveryText() {
  // const [district, setDistrict] = useState({});
  // const retrieved = useDistrict();
  const district = useDistrict();

  // useEffect(() => {
  //   // const retrieved = localStorage.getItem('district');
  //   if (retrieved !== undefined) {
  //     setDistrict(retrieved);
  //   }
  // }, []);

  return (
    <div
      style={{
        marginTop: "1rem",
        display: "flex",
        fontWeight: "500",
        fontSize: "x-large",
      }}
    >
      <div>Livraison à {district?.name} - </div>
      <Link
        style={{
          color: "#00A6A8",
          marginLeft: "0.3rem",
          textDecoration: "none",
          cursor: "pointer",
        }}
        to={"/locate"}
      >
        Modifier
      </Link>
    </div>
  );
}
