import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/admin.module.css";
import mystyles from "../../styles/dashboard.module.css";
import {
  faCartShopping,
  faDollar,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";

export default function SalesAppareils() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const iconsMap = {
    cash: faDollar,
    Maxit: faCartShopping,
    "Orange Money": faCartShopping,
    wave: faDesktop,
  };

  const allowedKeys = ["Orange Money", "cash", "wave", "Maxit"]; // Propriétés à afficher

  // Mapping des noms des canaux vers des titres personnalisés
  const channelTitles = {
    "": "Autre",
    mobile: "Application Mobile",
    wmobile: "Navigateur Téléphone",
    web: "Navigateur Ordinateur",
  };

  useEffect(() => {
    axiosInstance
      .get("appareils/")
      .then((response) => {
        setData(response.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="pb-5">
      <AdminHeader />
      <AdminSidebar />
      <div className={styles.container}>
        <div>
          {Object.entries(data)
            // Trier les canaux pour mettre "Autre" à la fin
            .sort(([channelA], [channelB]) => {
              if (channelA === "" || channelTitles[channelA] === "Autre")
                return 1; // Mettre "Autre" à la fin
              if (channelB === "" || channelTitles[channelB] === "Autre")
                return -1;
              return 0; // Garde l'ordre des autres éléments
            })
            .map(([channel, channelData]) => (
              <div key={channel} className="mb-4 text-start">
                {/* Titre du canal */}
                <h3 className="mb-3">
                  {channelTitles[channel] ||
                    channel.charAt(0).toUpperCase() + channel.slice(1)}
                </h3>
                <div className="row">
                  {Object.entries(channelData)
                    .filter(([key]) => allowedKeys.includes(key)) // Filtrer uniquement les clés autorisées
                    .map(([key, value]) => (
                      <div key={key} className="col-md-3 mb-3">
                        <div className="card shadow-sm">
                          <div className="card-body d-flex justify-content-between align-items-center">
                            <div>
                              {key === "wave" ? (
                                <img
                                  src={`/assets/wave.png`} // Assumes you have images named like the method name
                                  alt={`wave Payment`}
                                  style={{ height: "40px", width: "auto" }}
                                  className="mb-1"
                                />
                              ) : key === "cash" ? (
                                <img
                                  src={`/assets/cash.png`} // Assumes you have images named like the method name
                                  alt={`cash Payment`}
                                  style={{ height: "40px", width: "auto" }}
                                  className="mb-1"
                                />
                              ) : key === "Orange Money" ? (
                                <img
                                  src={`/assets/om.png`} // Assumes you have images named like the method name
                                  alt={`om Payment`}
                                  style={{ height: "40px", width: "auto" }}
                                  className="mb-1"
                                />
                              ) : key === "Maxit" ? (
                                <img
                                  src={`/assets/maxit.png`} // Assumes you have images named like the method name
                                  alt={`maxit Payment`}
                                  style={{ height: "40px", width: "auto" }}
                                  className="mb-1"
                                />
                              ) : (
                                <h5 className="card-title">Autre</h5>
                              )}
                              {/* <p className="card-text">Transactions</p> */}
                              <h5 className="card-title"> {key}</h5>
                            </div>
                            <div
                              className="rounded-circle d-flex align-items-center justify-content-center fw-bold"
                              style={{
                                width: "60px",
                                height: "60px",
                                backgroundColor: "#A7F3D0",
                              }}
                            >
                              {value?.toLocaleString() || 0}
                            </div>
                          </div>
                          {/* <div className="card-footer bg-white">
                            <h6 className="text-end mb-0">
                              {value?.toLocaleString() || 0}
                            </h6>
                          </div> */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  //   return (
  //     <div>
  //       <AdminHeader />
  //       <AdminSidebar />
  //       <div className={styles.container}>
  //         <div className={``}>
  //           {Object.entries(data).map(([channel, channelData]) => (
  //             <div key={channel} className="mb-4">
  //               {/* Titre du canal */}
  //               <h3 className="mb-3">
  //                 {channelTitles[channel] ||
  //                   channel.charAt(0).toUpperCase() + channel.slice(1)}
  //               </h3>
  //               <div className="row">
  //                 {Object.entries(channelData)
  //                   .filter(([key]) => allowedKeys.includes(key)) // Filtrer uniquement les clés autorisées
  //                   .map(([key, value]) => (
  //                     <div key={key} className="col-md-3 mb-3">
  //                       <div className="card shadow-sm">
  //                         <div className="card-body d-flex justify-content-between align-items-center">
  //                           <div>
  //                             <h5 className="card-title">{key}</h5>
  //                             <p className="card-text">Transactions</p>
  //                           </div>
  //                           <div
  //                             className="rounded-circle d-flex align-items-center justify-content-center"
  //                             style={{
  //                               width: "50px",
  //                               height: "50px",
  //                               backgroundColor: "#A7F3D0",
  //                             }}
  //                           >
  //                             <FontAwesomeIcon
  //                               icon={iconsMap[key] || faCartShopping} // Icône par défaut si non définie
  //                               color={"#0E805E"}
  //                               size={"lg"}
  //                             />
  //                           </div>
  //                         </div>
  //                         <div className="card-footer bg-white">
  //                           <h6 className="text-end mb-0">
  //                             {value?.toLocaleString() || 0}
  //                           </h6>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   ))}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   );
}
