import React, { useEffect, useState } from "react";
import styles from "../home/home.module.css";
import ShopHeader from "../components/header/header";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import { PrimaryButton } from "../../admin/components/inputs";
import GoogleMapReact from "google-map-react";
import PageTitle from "../components/page_title/page_title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import LoginPopup from "../components/login_popup/login_popup";
import Select from "../../components/ajonjolib/inputs/select/select";
import {
  ToastTypes,
  toast,
} from "../../components/ajonjolib/toasts/toast/toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDistrict } from "../../stores/features/userSlice";
import useDistrict from "../../hooks/useDistrict";
import { useQuery } from "@tanstack/react-query";
import useLocation from "../../hooks/useLocation";
// import useAuthenticate from "../../hooks/useAuthenticate";

export default function Locate() {
  const district = useDistrict();
  // const [district, setDistricts] = useState([]);

  const { localizeMe, getAddresValidation, location, allowedLocation } =
    useLocation();

  const [form, setForm] = useState({
    address: "MGXJ+FG9, Dakar, Senegal",
    lat: district?.form?.lat || 14.698311,
    lng: district?.form?.lng || -17.46878,
  });

  const [center, setCenter] = useState(location);

  // const [address, setAddress] = useState("MGXJ+FG9, Dakar, Senegal");
  const [showLogin, setShowLogin] = useState(false);
  const [_, setLockCenter] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canChangeLocation, setCanChangeLocation] = useState(false);

  const dispatch = useDispatch();

  // let authenticated = useAuthenticate() !== null;

  const navigate = useNavigate();

  const mapOptions = {
    center: {
      lat: 14.698311,
      lng: -17.46878,
    },
    zoom: 16,
  };

  const findAddress = async (lat, lng, override) => {
    setCenter({ lat, lng });
    setLockCenter(true);

    setForm({ ...form, lat, lng });
    try {
      const response = await axiosInstance.post(`set_location/`, {
        latitude: lat,
        longitude: lng,
      });




      if (response?.data) {
        return setSelectedDistrict(response.data?.id);
      }
      toast(
        "Nous ne livrons pas à cette adresse pour le moment",
        ToastTypes.ERROR
      );
    } catch (error) {
      console.error("Error finding address", error);
    } finally {
      if (!canChangeLocation) {
        setCanChangeLocation(true);
      }
    }
  };

  const districtQuery = useQuery({
    queryKey: ["district"],
    queryFn: async () => {
      const { data } = await axiosInstance.get("district/");
      return data;
    },
  });

  useEffect(() => {
    document.title = "Locate | Bring Me";
    // axiosInstance.get("district/").then((response) => {
    //   setDistricts(response.data);
    // });

    // const timer = setTimeout(() => localizeMe(true), 10);
    // return () => {
    //   clearTimeout(timer);
    // };
    localizeMe(false, (lat, lng) => {
      findAddress(lat, lng);
    });
  }, []);

  // const getAddresValidation = async () => {
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${form.lat},${form.lng}&key=AIzaSyBZmhQsod4pJDQvuctemtOfYnaRzOMsttg`
  //   );
  //   const data = await response.json();

  //   if (data.results.length > 0) {
  //     return data.results[0].formatted_address;
  //   }

  //   return null;
  // };

  const submit = async () => {
    const addressResponse = await getAddresValidation(form.lat, form.lng);
    if (!addressResponse) {
      return;
    }
    setLoading(true);

    if (addressResponse !== "" && addressResponse) {
      axiosInstance
        .post(`set_location/`, {
          latitude: form.lat,
          longitude: form.lng,
          address: addressResponse,
        })
        .then((response) => {
          if (response.data) {
            localStorage.setItem("district", JSON.stringify(response.data));
            dispatch(setDistrict({ ...response.data, addressResponse, form }));
            // window.location.replace("/");
            navigate("/", { replace: true });
          } else {
            toast(
              "Nous ne livrons pas à cette adresse pour le moment",
              ToastTypes.ERROR
            );
          }
        })
        .catch((error) => console.log("heljsdfk"))
        .finally(() => setLoading(false));
    }
  };

  const handlePinClick = ({ lat, lng }) => {
    findAddress(lat, lng, false);
  };

  // const localizeMe = (override) => {
  //   console.log("localizeMe");
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setForm({
  //         ...form,
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //       findAddress(
  //         position.coords.latitude,
  //         position.coords.longitude,
  //         override
  //       );
  //     },
  //     (error) => {
  //       if (error.code === error.PERMISSION_DENIED) {
  //         alert(
  //           'La localisation est bloquée. Veuillez autoriser l\'accès à la localisation pour utiliser "me localiser".'
  //         );
  //       } else {
  //         alert(
  //           "Une erreur est survenue lors de la tentative de localisation."
  //         );
  //       }
  //     }
  //   );
  // };

  return (
    <div className={styles.mainContainer}>
      <ShopHeader />
      <LoginPopup
        show={showLogin}
        setShow={setShowLogin}
        onSuccess={() => setShowLogin(false)}
      />

      <div style={{ paddingTop: "80px" }} className={"pb-5"}>
        <div className={styles.hideMobile}>
          <PageTitle name={"Où êtes-vous ?"} />
        </div>
        <div
          className={`d-flex justify-content-between px-4 flex-wrap ${styles.responsiveContainer}`}
        >
          <div className={styles.halfContainer}>
            <div className={"mb-2"} />
            <div className={styles.mapResponsive}>
              <GoogleMapReact
                options={{
                  fullscreenControl: false,
                  zoomControl: false,
                }}
                onClick={handlePinClick}
                defaultCenter={!allowedLocation ? mapOptions.center : undefined}
                defaultZoom={mapOptions.zoom}
                bootstrapURLKeys={{
                  key: "AIzaSyBZmhQsod4pJDQvuctemtOfYnaRzOMsttg",
                }}
                center={center}
                onChange={(event) => {
                  findAddress(event.center.lat, event.center.lng, false);
                }}
                onGoogleApiLoaded={(props) => {
                  // console.log({ props });
                  setCanChangeLocation(true);
                  // if (center) {
                  //   findAddress(center.lat, center.lng, false);
                  // }
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                <div
                  lat={form.lat}
                  lng={form.lng}
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <FontAwesomeIcon icon={faMapPin} color={"#F00"} size={"xl"} />
                </div>
              </GoogleMapReact>
              <div
                style={{
                  position: "absolute",
                  marginTop: "-70px",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "1rem",
                  right: "0",
                }}
              >
                <PrimaryButton
                  name={"Me localiser"}
                  onClick={() =>
                    localizeMe(false, (lat, lng) => {
                      findAddress(lat, lng);
                    })
                  }
                />
              </div>
            </div>

            <div className={"mb-2"} />
          </div>
          <div className={styles.halfContainer}>
            <div
              className={"mb-1"}
              style={{ color: "#4B5563", textAlign: "left", fontWeight: "500" }}
            >
              Quartier
            </div>
            <Select
              placeholder={"Choisir votre address"}
              options={
                districtQuery?.data
                  ? districtQuery?.data?.map((x) => {
                      return { name: x.name, value: x.id };
                    })
                  : []
              }
              value={selectedDistrict}
              onChange={(value) => {
                setLockCenter(true);
                const found = districtQuery?.data.find(
                  (district) => district.id === value[0]
                );

                if (found) {
                  findAddress(found.latitude, found.longitude, false);
                }
                setSelectedDistrict(value[0]);
              }}
              disabled={loading}
            />
            <div className={"mb-3"} />
            <PrimaryButton
              name={loading ? "Chargement en cours..." : "Valider"}
              onClick={submit}
              disabled={loading}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
