import React, { useCallback } from "react";
import styles from "./confirmation.module.css";
import ShopHeader from "../components/header/header";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formatPrice } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function getQueryParamValue(queryString, keySubstring) {
  const params = new URLSearchParams(queryString);

  // Parcours des paramètres pour trouver ceux qui incluent le substring spécifié
  for (let [key, value] of params.entries()) {
    if (key.includes(keySubstring)) {
      return value;
    }
  }

  // Si aucun paramètre n'est trouvé, retourne null ou un message d'erreur
  return null;
}

export default function Confirmation() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [queris] = useSearchParams();

  const next = useCallback(() => {
    // window.location.replace("/");
    navigate("/", { replace: true, preventScrollReset: true });
  }, []);

  const url = new URL(window.location.href);

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div className={styles.boxContainer}>
        <div>Résumé de commande</div>

        <div className={"mt-2 mb-4"}>
          {/* <div className={'fw-bolder'} style={{fontWeight: '1.4rem'}}>Merci {state.userData?.user_data?.name} {state.userData?.user_data?.lastname} !</div> */}
          <div className={"fw-bolder"} style={{ fontWeight: "1.4rem" }}>
            Merci !
          </div>
          <FontAwesomeIcon color={"#46b38c"} icon={faCheck} size={"5x"} />
          <div>Votre commande est en préparation!</div>
        </div>

        <div className={"mb-4"}>
          Merci de garder votre téléphone près de vous pour assurer le bon
          déroulement de notre service!
        </div>

        {/* {state?.total || queris.get("total") ? (
          <div>Total: {formatPrice(state.total || queris.get("total"))} FCFA</div>
        ) : null} */}

        {state ? (
          <div>Total: {parseInt(state.total)} FCFA</div>
        ) : getQueryParamValue(url.search, "total") ? (
          <div>Total: {getQueryParamValue(url.search, "total")} FCFA</div>
        ) : null}

        {state
          ? !!+state.gain && (
              <div
                style={{
                  color: "#20B880",
                }}
              >
                Vous avez gagné: {formatPrice(state.gain)} points Bring Me
              </div>
            )
          : queris.get("gain")
          ? !!+parseInt(+queris.get("gain")) && (
              <div
                style={{
                  color: "#20B880",
                }}
              >
                Vous avez gagné: {formatPrice(queris.get("gain"))} points Bring
                Me
              </div>
            )
          : null}

        <div
          className={`${styles.orderButton} pe-2 ps-4 mt-3`}
          onClick={() => next()}
        >
          <div className={"px-5 py-2"}>Continuer</div>
        </div>
      </div>
    </div>
  );
}
