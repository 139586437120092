import axios from "axios";
import { store } from "./stores";
import { logout, setAccessToken } from "./stores/features/userSlice";
// import { history } from "./utils/navigateHelper";

// let baseURL = "http://localhost:8000/api/";
// let baseURL = "https://c961-154-125-21-0.ngrok-free.app/api/";
// export let baseURL = "https://devbackend.indilma.sn/api/";
export let baseURL = "https://backend.indilma.sn/api/";
export let baseURLFront = "https://bringme.sn/";
// export let baseURLFront = "https://devfrontend.indilma.sn/";

let refresh = false;
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
});

// axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${
//   store?.getState()?.persist?.user?.access_token
// }`;

// Intercepteur pour ajouter automatiquement l'access token à chaque requête
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.persist?.user?.access_token;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error?.response?.status === 401) {
      refresh = true;
      axiosInstance
        .post("token/refresh/", {
          refresh: store?.getState()?.persist?.user?.refresh_token,
        })
        .then((response) => {
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data["access"]}`;
          // localStorage.setItem("access_token", response.data.access);
          // window.location.reload();
          store.dispatch(setAccessToken(response.data.access));
        })
        .catch((err) => {
          // localStorage.clear();
          // axiosInstance.defaults.headers.common["Authorization"] = null;
          window.location.href = "/locate";
          store.dispatch(logout());
          // history.push("/locate");
          console.log("logout here");
        });
    }
    refresh = false;
    return error;
  }
);

export default axiosInstance;
