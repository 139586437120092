import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import { ContextProvider } from "./MyContext";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { persistor, store } from "./stores";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorProvider } from "./providers/ErrorProvider";
import { ErrorPopup } from "./components/ErrorPopup";
import { NuqsAdapter } from "nuqs/adapters/react";

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: { queries: { gcTime: 20 * 60 * 1000 * 60, retry: 2 } },
});

if (!window.location.host.includes("localhost")) {
  console.log = function () {};
}

function App() {
  return (
    <div className="App">
      <ErrorProvider>
        <ErrorPopup />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <ContextProvider>
                <NuqsAdapter>
                  <Router>
                    <RouteContainer />
                  </Router>
                  <ToastContainer />
                </NuqsAdapter>
              </ContextProvider>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </ErrorProvider>
    </div>
  );
}

export default App;
