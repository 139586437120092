import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../AxiosInstance";
import useDistrict from "./useDistrict";
import { useSelector } from "react-redux";
import {
  // getUserAuthorizedAddress,
  // setAuthorizedAddress,
  getUserToken,
} from "../stores/features/userSlice";
// import { useNavigate } from "react-router-dom";
import useAuthenticate from "./useAuthenticate";
// import { useState } from "react";

function useGetUser() {
  const district = useDistrict();
  // const permissions = parseInt(localStorage.getItem("permissions"));
  const access_token = useSelector(getUserToken);
  // const authorizedAddress = useSelector(getUserAuthorizedAddress);
  const isAuth = useAuthenticate();
  // const navigate = useNavigate();

  // const dispatch = useDispatch();

  // const userQuery = useQuery({
  //   queryKey: ["user"],
  //   queryFn: async () => {
  //     const { data } = await axiosInstance.get("current_user/");
  //     if (data?.response?.status === 500) {
  //       // localStorage.removeItem("access_token");
  //       return undefined;
  //     }
  //     if (district?.id !== data?.user_data?.district?.id && access_token) {
  //       if (!permissions) {
  //         if (window.location.pathname !== "/locate") {
  //           alert("Veuillez mettre à jour votre position");
  //           // window.location.href = "/locate";
  //           navigate("/locate", { replace: true });
  //         }
  //       }
  //     }
  //     return data;
  //   },
  // });

  const userQuery = useQuery({
    queryKey: ["user", { query: isAuth, district }],
    staleTime: 100000,
    enabled: isAuth,
    queryFn: async () => {
      try {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        const { data } = await axiosInstance.get("current_user/");

        // Vérification si la réponse est valide
        if (!data || !data.user_data) {
          throw new Error("Invalid user data");
        }

        // Vérifie la position actuelle de l'utilisateur
        // if (district?.id !== data?.user_data?.district?.id && access_token) {
        //   if (district?.id !== authorizedAddress) {
        //     if (!permissions) {
        //       if (window.location.pathname !== "/locate") {
        //         const confirmRes = window.confirm(
        //           "Voulez-vous mettre à jour votre position"
        //         );
        //         if (confirmRes) {
        //           navigate("/locate", { replace: true });
        //         } else {
        //           dispatch(setAuthorizedAddress(district?.id));
        //         }
        //       }
        //     }
        //   }
        // }

        return data;
      } catch (error) {
        // console.error(
        //   "Erreur lors de la récupération des données utilisateur :"
        //   // error
        // );

        // Gestion des erreurs 500 spécifiques
        if (error.response?.status === 500) {
          console.warn("Erreur 500 - Serveur indisponible");
          localStorage.removeItem("access_token");
          return undefined;
        }

        throw error; // Propager les autres erreurs pour React Query
      }
    },
  });

  if (userQuery?.isError) {
    return null;
  }

  return { isLoading: userQuery?.isLoading, data: userQuery?.data };
}

export default useGetUser;
