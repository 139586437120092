import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faPenToSquare,
  faTrash,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { useNavigate } from "react-router-dom";
import MyContext from "../../../MyContext";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatTimestamp } from "../../components/utils";
import usePermissions from "../../../hooks/usePermissions";

export default function StockList() {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const { setEditing } = useContext(MyContext);
  const navigate = useNavigate();
  // const permissions = parseInt(localStorage.getItem("permissions"));
  const permissions = usePermissions();

  const deleteObj = (id) => {
    axiosInstance.delete(`stock/${id}`).then((response) => {
      window.location.reload();
    });
  };

  const editObj = (data) => {
    setEditing(data);
    navigate("/admin/stock/create");
  };

  const transferObj = (data) => {
    setEditing(data);
    navigate("/admin/stock/transfer");
  };

  useEffect(() => {
    let tempCols = [];

    tempCols.push({
      title: "Product",
      internal: "product.name",
      filter: true,
      filterInternal: "product",
    });
    tempCols.push({
      title: "Warehouse",
      internal: "warehouse.name",
      filterInternal: "warehouse",
      filter: true,
    });
    if (permissions === 1)
      tempCols.push({
        title: "Supplier",
        internal: "biller.name",
        filterInternal: "supplier",
        filter: true,
      });
    if (permissions === 1)
      tempCols.push({
        title: "Quantity",
        internal: "purchased_quantity",
        filter: true,
      });
    tempCols.push({
      title: "Actual",
      internal: "quantity",
      filter: true,
      code: (row, data) => {
        return row.quantity;
      },
    });
    tempCols.push({
      title: "Expiry Date",
      internal: "expiry_date",
      code: (row, data) => {
        return formatTimestamp(data);
      },
    });
    tempCols.push({
      title: "Creation Date",
      internal: "creation_date",
      code: (row, data) => {
        return formatTimestamp(data);
      },
    });
    tempCols.push({
      title: "Updated Date",
      internal: "updated_timestamp",
      code: (row, data) => {
        return formatTimestamp(data);
      },
    });
    if (permissions === 1)
      tempCols.push({
        title: "Unit Cost",
        internal: "total_cost",
        code: (row, data) => {
          return parseInt(data / row.purchased_quantity);
        },
      });
    if (permissions === 1)
      tempCols.push({
        title: "Actions",
        internal: "id",
        code: (data, row) => {
          return (
            <div className={"d-flex justify-content-end"}>
              <div
                role={"button"}
                onClick={() => editObj(data)}
                className={"pe-3"}
              >
                <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
              </div>

              <div
                role={"button"}
                onClick={() => transferObj(data)}
                className={"pe-3"}
              >
                <FontAwesomeIcon icon={faTruck} color={"#9EA3AC"} />
              </div>

              <div
                role={"button"}
                onClick={() => {
                  setShowDelete(true);
                  setSelectedId(row);
                }}
              >
                <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
              </div>
            </div>
          );
        },
      });

    setCols(tempCols);
  }, []);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4 pb-5`}>
        <TableView
          fetchUrl={"stock"}
          pull={setRows}
          title={"stock"}
          cols={cols}
          rows={rows}
          withIndex={true}
          noCreate={true}
          withImport={true}
          useReactQuery={"stock-list"}
        >
          {permissions === 1 && (
            <div className={`d-flex mt-3 justify-content-end`}>
              <div
                className={`${styles.tableButton} me-3`}
                role={"button"}
                onClick={() => navigate("/admin/stock/auto_multi_transfer")}
              >
                Auto Transfer
              </div>
              <div
                className={`${styles.tableButton} me-3`}
                role={"button"}
                onClick={() => navigate("/admin/stock/multi_transfer")}
              >
                Transfer
              </div>
              <div
                className={`${styles.tableButton} me-3`}
                role={"button"}
                onClick={() => navigate("/admin/stock/transfer_history")}
              >
                Transfer History
              </div>
              <div
                className={`${styles.tableButton}`}
                role={"button"}
                onClick={() => navigate("/admin/stock/history")}
              >
                Stock History
              </div>
            </div>
          )}
        </TableView>
      </div>
    </div>
  );
}
