// import { useState } from "react";
// import axiosInstance from "../../AxiosInstance";
// import { useQuery } from "@tanstack/react-query";

// export function useTableData(
//   fetchUrl,
//   defaultFilters,
//   limit,
//   name,
//   refetchInterval
// ) {
//   const [currentPage, setCurrentPage] = useState(1);

//   const fetchTableData = async ({ queryKey }) => {
//     const [_key, { page, filters }] = queryKey;
//     const queryParams = new URLSearchParams({
//       ...filters,
//       limit,
//       offset: (page - 1) * limit,
//       page,
//     }).toString();

//     console.log({ queryParams });

//     const response = await axiosInstance.get(`${fetchUrl}/?${queryParams}`);
//     return response.data;
//   };

//   console.log("useTableData");

//   const { data, error, isLoading } = useQuery({
//     queryKey: [
//       `tableData-${name}`,
//       { page: currentPage, filters: defaultFilters },
//     ],
//     queryFn: fetchTableData,
//     // keepPreviousData: true,
//     refetchInterval: refetchInterval,
//   });

//   return { data, error, isLoading, setCurrentPage };
// }


import { useState } from "react";
import axiosInstance from "../../AxiosInstance";
import { useQuery } from "@tanstack/react-query";

export function useTableData(fetchUrl, defaultFilters, limit, name, refetchInterval) {
  const [currentPage, setCurrentPage] = useState(1);

  const fetchTableData = async ({ queryKey }) => {
    const [_key, { page, filters }] = queryKey;
    const queryParams = new URLSearchParams({
      ...filters,
      limit,
      offset: (page - 1) * limit,
      page,
    }).toString();

    console.log({ queryParams });

    const response = await axiosInstance.get(`${fetchUrl}/?${queryParams}`);
    return response.data;
  };

  const { data, error, isLoading } = useQuery({
    queryKey: [`tableData-${name}`, { page: currentPage, filters: defaultFilters }],
    queryFn: fetchTableData,
    // keepPreviousData: true,
    refetchInterval: refetchInterval
  });

  return { data, error, isLoading, setCurrentPage };
}