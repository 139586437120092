import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import ShopHeader from "../components/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLock,
  faPersonBiking,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import Footer from "../components/footer/footer";
// import PageTitle from "../components/page_title/page_title";
// import DeliveryText from "../components/delivery_text/delivery_text";
import Loading from "../../admin/components/loading";
import Cart from "../components/cart/cart";
import { useQuery } from "@tanstack/react-query";
import { filterHomeProductData } from "../../utils/utils";
import useDistrict from "../../hooks/useDistrict";
// import useGetUser from "../../hooks/useGetUser";
import { queryClient } from "../../App";

function DeliveryFooter({ times, cost }) {
  return (
    <div className={styles.deliveryFooter}>
      <FontAwesomeIcon icon={faPersonBiking} className={"pt-1 pe-1"} />
      <div>
        {times} • {cost}Fr
      </div>
    </div>
  );
}

function Section({ data, reelUrl, key, warehouseFee }) {
  const navigate = useNavigate();

  if (data.display === false) return <></>;

  return (
    <div className={styles.sectionContainer} key={key}>
      <div className={styles.sectionTitle}>
        <div className={"fw-bold fs-3"}>{data.name}</div>
        <Link to={"/"}>
          {reelUrl && (
            <div>
              <div>Voir Tout</div>
              <FontAwesomeIcon icon={faChevronRight} className={"pt-1 ps-2"} />
            </div>
          )}
        </Link>
      </div>
      <div
        className={styles.mainSectionImage}
        onClick={() => navigate("/categories/", { state: data })}
      >
        <img src={data?.image?.image} alt={"section"} />
      </div>
      <div style={{ textAlign: "left" }}>{data.description}</div>
      <DeliveryFooter times={"20 min"} cost={warehouseFee} />
    </div>
  );
}

function SectionAlt({ data, key, handleMouseDown }) {
  const navigate = useNavigate();

  return (
    <div className={styles.billerItem} key={key}>
      <div style={{ margin: "0.5rem 0" }}>
        <div
          className={styles.sectionImage}
          onClick={() => {
            navigate(`/${data.slug}`, { state: data });
          }}
          onMouseDown={handleMouseDown}
          // onMouseOver={handleMouseDown}
        >
          <div style={{ position: "relative" }}>
            {!data.is_open && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                  borderRadius: "15px",
                  userSelect: "none",
                }}
              >
                <div
                  style={{ backgroundColor: "#009F7F" }}
                  className={"d-flex px-3 py-1 align-items-center rounded-3"}
                >
                  <div className={"pe-2"}>
                    <FontAwesomeIcon icon={faLock} color={"#FFF"} />
                  </div>
                  <div style={{ color: "#FFF", fontWeight: 500 }}>Fermé</div>
                </div>
              </div>
            )}
            <img alt={data?.name} src={data?.image?.image} />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "left" }}>{data?.name}</div>
      <DeliveryFooter times={data.delivery_time} cost={data.fee} />
    </div>
  );
}

export default function Home() {
  const navigate = useNavigate();
  // const [sections, setSections] = useState([]);
  // const [restaurants, setRestaurants] = useState([]);
  // const [stores, setStores] = useState([]);
  // const [finishedLoading, setFinishedLoading] = useState(false);
  const district = useDistrict();
  // const user = useGetUser();

  const [warehouseFee, setWarehouseFee] = useState(district?.delivery_fee || 0);

  // Queries
  const sectionQuery = useQuery({
    queryKey: ["sections"],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`section/`);

      return data;
    },
  });

  // Queries
  const restaurantQuery = useQuery({
    queryKey: ["restaurants", { query: district?.id }],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `c/suppliers/?section_id=7&district_id=${district?.id}`
      );

      return data;
    },
  });

  // Queries
  const storeQuery = useQuery({
    queryKey: ["stores", { query: district?.id }],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `c/suppliers/?section_id=8&district_id=${district?.id}`
      );

      return data;
    },
  });

  useEffect(() => {
    if (sectionQuery?.data) {
      (async () => {
        await queryClient.prefetchQuery({
          queryKey: [{ type: "sections", id: 6 }],
          queryFn: async () => {
            const { data } = await axiosInstance.get(`section/6/`);

            if (data) {
              return [
                {
                  id: -1,
                  name: "Promotions",
                  image: { image: "/promo.png" },
                },
                ...data,
              ];
            }
            return data;
          },
        });
      })();
    }
  }, [sectionQuery?.data]);

  // console.log({ sectionQuery, restaurantQuery });

  useEffect(() => {
    document.title = "Home | Bring Me";

    // const foundDistrict = JSON.parse(localStorage.getItem("district"));

    // axiosInstance.get("section/").then((response) => {
    //   setSections(response.data);
    // });

    // axiosInstance
    //   .get(`supplier/?section_id=7&district_id=${foundDistrict.id}`)
    //   .then((response) => {
    //     let filteredData = response.data.filter(
    //       (item) => item.fee !== undefined
    //     );
    //     filteredData.sort((a, b) => a.fee - b.fee);
    //     filteredData.sort((a, b) => b.is_open - a.is_open);
    //     setRestaurants(filteredData);
    //     setFinishedLoading(true);
    //   });

    // axiosInstance
    //   .get(`supplier/?section_id=8&district_id=${foundDistrict.id}`)
    //   .then((response) => {
    //     // sdlfjsd
    //     const filteredData = response.data.filter(
    //       (item) => item.fee !== undefined
    //     );
    //     filteredData.sort((a, b) => a.fee - b.fee);
    //     filteredData.sort((a, b) => b.is_open - a.is_open);
    //     setStores(filteredData);
    //   });
  }, []);

  // console.log({ restaurantQuery });

  const handleMouseDown = async (data) => {
    // console.log("start");

    try {
      // alert(data.slug); // Affiche le slug pour vérifier l'interaction utilisateur
      if (!queryClient.getQueryData(["product-details", { id: data?.id }])) {
        await queryClient.prefetchQuery({
          queryKey: ["product-details", { id: data?.id }],
          queryFn: async () => {
            const response = await axiosInstance.get(
              `/subcategory/?biller_id=${data?.id}`
            );
            return response.data?.results;
          },
        });
        console.log("Préfetching terminé avec succès !");
      }
    } catch (error) {
      console.error("Erreur lors du préfetching :", error);
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <ShopHeader />

      <Cart setWarehouseFee={setWarehouseFee} />

      <div style={{ paddingTop: "80px" }}>
        <div className={styles.responsiveContainer}>
          <div className={styles.webMargin}>
            <div className={"d-flex flex-wrap"}>
              {sectionQuery?.isLoading ? (
                <Loading />
              ) : sectionQuery?.isError ? (
                <div>Une erreur est survenue</div>
              ) : sectionQuery?.data && sectionQuery?.data?.length > 0 ? (
                <Section
                  warehouseFee={warehouseFee}
                  data={sectionQuery?.data[0]}
                />
              ) : (
                <p>Pas de donnees</p>
              )}
            </div>

            <div className={"d-flex"}>
              <div className={styles.billerContainer}>
                <div
                  className={
                    "d-flex align-items-center justify-content-between w-100"
                  }
                >
                  <div className={"fw-bold fs-3"}>
                    {"Restaurants et autres"}
                  </div>
                  {restaurantQuery?.data && (
                    <div className={styles.seeAll}>
                      <div
                        className={"pe-2"}
                        onClick={() =>
                          navigate("/billers/restautrants", {
                            state: {
                              data:
                                (restaurantQuery?.data &&
                                  filterHomeProductData(
                                    restaurantQuery?.data
                                  )) ||
                                [],
                              id: 7,
                              type: "restaux",
                              title: "Restaurants et autres",
                            },
                          })
                        }
                      >
                        Voir Tout
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  )}
                </div>
                <div className={styles.billerInnerContainer}>
                  {restaurantQuery?.isLoading ? (
                    <Loading />
                  ) : restaurantQuery?.isError ? (
                    <div>Une erreur est survenue</div>
                  ) : (
                    restaurantQuery?.isSuccess &&
                    (restaurantQuery?.data &&
                    restaurantQuery?.data?.length > 0 ? (
                      filterHomeProductData(restaurantQuery?.data)?.map(
                        (store, index) => {
                          return (
                            <SectionAlt
                              data={store}
                              key={index}
                              handleMouseDown={() => handleMouseDown(store)}
                            />
                          );
                        }
                      )
                    ) : (
                      <p>Pas de donnees</p>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div className={"d-flex w-100"}>
              <div className={styles.billerContainer}>
                <div
                  className={
                    "d-flex align-items-center justify-content-between w-100"
                  }
                >
                  <div className={"fw-bold fs-3"}>{"Nos Commerces"}</div>
                  {storeQuery?.data && (
                    <div className={styles.seeAll}>
                      <div
                        className={"pe-2"}
                        onClick={() =>
                          navigate("/billers/commerces", {
                            state: {
                              data:
                                (storeQuery?.data &&
                                  filterHomeProductData(storeQuery?.data)) ||
                                [],
                              id: 8,
                              type: "commerce",
                              title: "Nos Commerces",
                            },
                          })
                        }
                      >
                        Voir Tout
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  )}
                </div>
                <div className={styles.billerInnerContainer}>
                  {storeQuery?.isLoading ? (
                    <Loading />
                  ) : storeQuery?.isError ? (
                    <div>Une erreur est survenue</div>
                  ) : storeQuery?.isSuccess &&
                    storeQuery?.data &&
                    storeQuery?.data?.length > 0 ? (
                    filterHomeProductData(storeQuery?.data)?.map(
                      (store, index) => {
                        return (
                          <SectionAlt
                            data={store}
                            key={index}
                            handleMouseDown={() => handleMouseDown(store)}
                          />
                        );
                      }
                    )
                  ) : (
                    <p>Pas de donnees</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              className={"my-4"}
              style={{ fontWeight: "500", fontSize: "large" }}
            >
              Le premier site qui propose divers services au Sénégal !
            </div>
            <div
              className={"d-flex flex-wrap justify-content-between mx-5"}
              style={{ textAlign: "left" }}
            >
              <div className={styles.quarterContainer}>
                <div style={{ fontWeight: "500" }}>L'épicerie en ligne !</div>
                <div style={{ fontSize: "small" }}>
                  Livraison en 30 minutes jour et nuit de tout le nécessaire où
                  que vous soyez ! Tout type de Boissons, Snacks, Charcuteries,
                  Epicerie fine, Fast-Food et encore plein d'autres catégories à
                  découvrir sur le site !
                </div>
              </div>
              <div className={styles.quarterContainer}>
                <div style={{ fontWeight: "500" }}>
                  Les restaurants, Boulangeries, Patisseries et Traiteurs !
                </div>
                <div style={{ fontSize: "small" }}>
                  Retrouvez votre établissement préféré ou découvrez-en un
                  nouveau parmis notre grande liste de collaborateurs !
                </div>
              </div>
              <div className={styles.quarterContainer}>
                <div style={{ fontWeight: "500" }}>Les Magasins !</div>
                <div style={{ fontSize: "small" }}>
                  Accedez directement sur votre téléphone à tous les magasins
                  qui livrent là où vous êtes le jour même !
                </div>
              </div>
              <div className={styles.quarterContainer}>
                <div style={{ fontWeight: "500" }}>Le Centre Commercial !</div>
                <div style={{ fontSize: "small" }}>
                  On faisait comment avant ? Vous y retrouverez une immense
                  variété de produits qui vous seront livrés sous quelques jours
                  !
                </div>
              </div>
            </div>
            <div
              style={{ fontSize: "large", fontWeight: "600" }}
              className={"my-5 mx-5"}
            >
              Un service de localisation simple et rafiné, une équipe qui va
              suivre votre commande jusqu'à ce qu'elle vous soit livrée. Le
              futur, c'est maintenant avec Bring Me !
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
