import React, { useEffect, useState } from "react";
import { Modal as BootStrapModal } from "react-bootstrap";
import styles from "../../products/products.module.css";
import { formatPrice } from "../../../utils/utils";

export default function OrderDetail({ showModal, setShowModal, order, user }) {
  const [variantTotal, setVariantTotal] = useState(0);

  useEffect(() => {
    if (!order) return;
    let total_variant_price = 0;
    order?.items?.forEach((item) => {
      let item_variant_price = 0;
      item?.variant_items?.forEach((variant) => {
        item_variant_price += variant.variant_item.price * item.qty;
      });
      item.variant_cost = item_variant_price;
      total_variant_price += item_variant_price;
    });
    setVariantTotal(total_variant_price);
  }, [order]);

  return (
    <BootStrapModal
      show={showModal}
      centered={true}
      onBackdropClick={() => setShowModal(false)}
      onHide={() => setShowModal(false)}
    >
      <div
        style={{
          borderBottom: "1px solid #CCC",
        }}
      >
        {order?.items?.map((item, index) => (
          <div key={index} className={"my-2 px-5 pt-2"}>
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <img
                src={item?.image ? item?.image?.image : "/placeholder.jpg"}
                alt={item.product_name}
                height={"100px"}
                width={"20%"}
                style={{
                  objectFit: "contain",
                }}
              />
              <div style={{ width: "75%" }}>
                <div>
                  <div>{item.product_name}</div>
                  <div style={{ fontSize: "12px", color: "#616161" }}>
                    {item.variant_items.map((variant) => {
                      return (
                        variant.variant_name +
                        ": " +
                        variant.variant_item.name +
                        ", "
                      );
                    })}
                  </div>
                </div>
                <div
                  className={
                    "d-flex justify-content-between align-items-center"
                  }
                  style={{ fontWeight: "bold" }}
                >
                  <div>
                    {formatPrice(
                      item.price +
                        (item.variant_cost ? item.variant_cost : 0) / item.qty
                    )}{" "}
                    FCFA
                  </div>
                  <div>{item.qty}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div
          className={
            "d-flex justify-content-between align-items-center px-5 my-2 pb-2 fw-bold"
          }
          style={{
            borderBottom: "1px solid #CCC",
          }}
        >
          <div>Total produits</div>
          <div>{formatPrice(order?.sub_total + variantTotal)} FCFA</div>
        </div>
        <div
          className={
            "d-flex justify-content-between align-items-center px-5 my-2 pb-2 fw-bold"
          }
          style={{
            borderBottom: "1px solid #CCC",
          }}
        >
          <div>Frais de livraison: </div>
          <div>{formatPrice(order?.fee)} FCFA</div>
        </div>
        <div
          className={
            "d-flex justify-content-between align-items-center px-5 mb-2 pb-2 fw-bold"
          }
        >
          <div>Total</div>
          <div>
            {formatPrice(order?.sub_total + order?.fee + variantTotal)} FCFA
          </div>
        </div>
      </div>
    </BootStrapModal>
  );
}
